import React, { useState } from "react";
import './Expander.scss';

export default function Expander({title, closedTitle, subcontent, children, isClosed, noBorder, caretOnRight}) {

  const [ open, setOpen ] = useState(!(isClosed === true))
  var expanderClass = "maintitle";
  var titleBarClass = "title-bar"
  if (subcontent) {
    expanderClass = "subtitle";
  }
  if (noBorder) {
    expanderClass += " no-border";
  }
  if (caretOnRight) {
    titleBarClass += " right-caret"
  }
  return (
    <div className="Expander">
      <div className={expanderClass}>
        <div className={titleBarClass} onClick={ () => setOpen(!open)}>
          { open ?
            <h4 className="title">{title}</h4>
          :
            <h4 className="title">{closedTitle || title}</h4>
          }
          { open ?
            <h4 className="caret">∧</h4>
          :
          <h4 className="caret">∨</h4>
          }
        </div>
        { open &&
          <div className="open">
            <div className="content">
              {children}
            </div>
          </div>
        }
      </div>
    </div>
  );
}