import React from "react";
import Patient from "./Patient";
import EngageRxLoading from "./EngageRxLoading";
import { searchUrlParams, isLocalhost } from "./shared/utils.js";

//const LOCAL_AUTH='https://engagerx.ucsf.edu/api/authenticate_local?patientMRN='

var LOCAL_AUTH = "/api/authenticate_local?patientMRN=";
var LOCAL_PREFETCH = "/api/prefetch-patient";
if (isLocalhost) {
  LOCAL_AUTH = "http://localhost:8081/api/authenticate_local?patientMRN=";
  LOCAL_PREFETCH = "http://localhost:8081/api/prefetch-patient";
}
//const LOCAL_AUTH='http://192.168.4.24:8081/api/authenticate_local?patientMRN='

export default class DebugLauncher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      //patientMRN: props.match.params.patientId,
      patientId: null,
      error: null,
      authStatus: "loading",
    };

    console.log(", debug props are: ", props);
    console.log(", url is: ", window.location.href);

    this.patientMRN = null;
    const patient_str = searchUrlParams("patientMRN");
    if (patient_str) {
      this.patient = patient_str;
    } else {
      console.log("No patient string given");
    }
  }

  componentDidMount() {
    const hardcodedMrn = searchUrlParams("hardcode");
    if (hardcodedMrn) {
      console.log("Using hardcoded data for ", hardcodedMrn);
      this.setState({
        accessToken: "dummyToken",
        patientId: "dummyPatientId",
        authStatus: "complete",
      });
    } else {
      this.authenticate(this.props.env);
    }
  }

  authenticate = (env) => {
    //console.log(`In authenticate using this token: ${token}`)
    const url = `${LOCAL_AUTH}${this.patient}&env=${env}`;
    console.log(`sending to this url ${url}`);

    fetch(url, { method: "GET" })
      .catch((error) => console.log("Error:", error))
      .then((response) => response.json())
      .then((data) => {
        console.log("In authenticate_local got this data returned");
        console.log(data);

        console.log(
          `In authenticate got this access_token: ${data.access_token}`
        );
        // this.setState({
        //   accessToken: data.access_token,
        //   patientId: data.patient,
        //   error: data.error,
        //   authStatus: "complete",
        // });

        //Temporary code to pre-fetching

        const prefetchUrl = LOCAL_PREFETCH + "?env=" + env;
        console.log("Gonna use this prefetchUrl", prefetchUrl);
        fetch(prefetchUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fhirAuthorization: {
              access_token: data.access_token,
            },
            context: {
              patientId: data.patient,
              //patientId: "dummy"
            },
          }),
        }).then((prefetchResponse) => {
          console.log("Got this response from prefetch", prefetchResponse);
          // Temp code to get the prefetch data
          this.setState({
            accessToken: data.access_token,
            patientId: data.patient,
          });
        });
      });
  };

  render() {
    const { accessToken, patientId, error } = this.state;
    console.log("In render have this accessToken", accessToken);
    console.log("In render have this error", error);

    return (
      <div id="UCSFLauncher">
        {error ? (
          <p>Got this error: {error}</p>
        ) : (
          <div>
            {accessToken ? (
              <Patient
                patientId={patientId}
                accessToken={accessToken}
                env={this.props.env}
              />
            ) : (
              <EngageRxLoading authStatus={this.state.authStatus} />
            )}
          </div>
        )}
      </div>
    );
  }
}
