const sampleMedications = [
    {
        "resourceType": "MedicationRequest",
        "id": "egEWOkhx3WP3UFgmcapET2lxhqZz3rXUxU.NPFey.pO03",
        "identifier": [
            {
                "use": "usual",
                "system": "urn:oid:1.2.840.114350.1.13.266.2.7.2.798268",
                "value": "518034979"
            }
        ],
        "status": "active",
        "intent": "order",
        "category": [
            {
                "coding": [
                    {
                        "system": "http://terminology.hl7.org/CodeSystem/medicationrequest-category",
                        "code": "community",
                        "display": "Community"
                    }
                ],
                "text": "Community"
            }
        ],
        "medicationReference": {
            "reference": "Medication/eGXA7.33pDDtDithJJ1amwD5UD8Z22ad-m9.ilg1SKBsxeJHGGR-2tTvG1PmmtTZRB2ae1YKUbCZkUwiVuEaFoL6eQLxxQXUbF2lgrOYKxQk3",
            "display": "metoprolol succinate (TOPROL-XL) 24 hr tablet",
            "xdisplay": "amLODIPine (NORVASC) tablet"
        },
        "subject": {
            "reference": "Patient/eeLXYHAezDPbPVhPeYwanRDH-YRMo3Jq3tWYaFkU2VXc3",
            "display": "Sotiriou, Celia Perez"
        },
        "encounter": {
            "reference": "Encounter/eW2yHInjRBUdXEHKj9awMOg3",
            "display": "Refill"
        },
        "authoredOn": "2023-01-22",
        "requester": {
            "reference": "Practitioner/e5fVfo0xCCXIWn8K6xmU14Q3",
            "type": "Practitioner",
            "display": "Radhika Anusha Ramanan, MD"
        },
        "recorder": {
            "reference": "Practitioner/e5fVfo0xCCXIWn8K6xmU14Q3",
            "type": "Practitioner",
            "display": "Radhika Anusha Ramanan, MD"
        },
        "reasonCode": [
            {
                "coding": [
                    {
                        "system": "http://snomed.info/sct",
                        "code": "59621000",
                        "display": "Essential hypertension (disorder)"
                    },
                    {
                        "system": "http://hl7.org/fhir/sid/icd-9-cm/diagnosis",
                        "code": "401.9",
                        "display": "Essential hypertension"
                    },
                    {
                        "system": "urn:oid:2.16.840.1.113883.6.90",
                        "code": "I10",
                        "display": "Essential hypertension"
                    }
                ],
                "text": "Essential hypertension"
            }
        ],
        "courseOfTherapyType": {
            "coding": [
                {
                    "system": "http://terminology.hl7.org/CodeSystem/medicationrequest-course-of-therapy",
                    "code": "acute",
                    "display": "Short course (acute) therapy"
                }
            ],
            "text": "Short course (acute) therapy"
        },
        "note": [
            {
                "text": "DX Code Needed  ."
            }
        ],
        "dosageInstruction": [
            {
                "text": "TAKE 1 TABLET BY MOUTH EVERY DAY, Normal",
                "patientInstruction": "TAKE 1 TABLET BY MOUTH EVERY DAY",
                "timing": {
                    "repeat": {
                        "boundsPeriod": {
                            "start": "2023-01-22"
                        }
                    }
                }
            }
        ],
        "dispenseRequest": {
            "validityPeriod": {
                "start": "2023-01-22"
            },
            "numberOfRepeatsAllowed": 3,
            "quantity": {
                "value": 90,
                "unit": "tablet"
            }
        },
        "priorPrescription": {
            "reference": "MedicationRequest/e6h19E8IPmYBhgGiGHFcPr5Qab659Zs.J0GdYUK39uqQ3",
            "display": "amLODIPine (NORVASC) 10 mg tablet"
        }
    },
    {
        "resourceType": "MedicationRequest",
        "id": "eN.alBGRZHgxpRetMTYX0oe0gd9bOftoNapc5GwQf6Ng3",
        "identifier": [
            {
                "use": "usual",
                "system": "urn:oid:1.2.840.114350.1.13.266.2.7.2.798268",
                "value": "531456597"
            }
        ],
        "status": "active",
        "intent": "order",
        "category": [
            {
                "coding": [
                    {
                        "system": "http://terminology.hl7.org/CodeSystem/medicationrequest-category",
                        "code": "community",
                        "display": "Community"
                    }
                ],
                "text": "Community"
            }
        ],
        "medicationReference": {
            "reference": "Medication/eUbrTEqiegyjudiMltNfVuqcsLmZEs88QR5Ju.wQbMCxH-OAahwAk1WjkVlU1VxRlhqN3GTFgId2mhvH-n6k-jrh7OAQCiRoK16YlLG2anMc3",
            "display": "metoprolol succinate (TOPROL-XL) 24 hr tablet"
        },
        "subject": {
            "reference": "Patient/eeLXYHAezDPbPVhPeYwanRDH-YRMo3Jq3tWYaFkU2VXc3",
            "display": "Sotiriou, Celia Perez"
        },
        "encounter": {
            "reference": "Encounter/e4Cv.TnfZJATduP8V4pp1QA3",
            "display": "Refill"
        },
        "authoredOn": "2023-06-07",
        "requester": {
            "reference": "Practitioner/e5fVfo0xCCXIWn8K6xmU14Q3",
            "type": "Practitioner",
            "display": "Radhika Anusha Ramanan, MD"
        },
        "recorder": {
            "reference": "Practitioner/e5fVfo0xCCXIWn8K6xmU14Q3",
            "type": "Practitioner",
            "display": "Radhika Anusha Ramanan, MD"
        },
        "courseOfTherapyType": {
            "coding": [
                {
                    "system": "http://terminology.hl7.org/CodeSystem/medicationrequest-course-of-therapy",
                    "code": "acute",
                    "display": "Short course (acute) therapy"
                }
            ],
            "text": "Short course (acute) therapy"
        },
        "note": [
            {
                "text": "PT REQUESTS REFILLS FOR METOPROLOL"
            }
        ],
        "dosageInstruction": [
            {
                "text": "TAKE 1 TABLET BY MOUTH EVERY DAY. GENERIC FOR TOPROL XL., Normal",
                "patientInstruction": "TAKE 1 TABLET BY MOUTH EVERY DAY. GENERIC FOR TOPROL XL.",
                "timing": {
                    "repeat": {
                        "boundsPeriod": {
                            "start": "2023-06-07"
                        }
                    }
                }
            }
        ],
        "dispenseRequest": {
            "validityPeriod": {
                "start": "2023-06-07"
            },
            "numberOfRepeatsAllowed": 3,
            "quantity": {
                "value": 90,
                "unit": "tablet"
            }
        },
        "priorPrescription": {
            "reference": "MedicationRequest/ep-001Z7e0tygmM5bDdg2.hiRQTFyqqJV6JQdSY-K8Hk3",
            "display": "metoprolol succinate (TOPROL-XL) 200 mg 24 hr tablet"
        }
    },

    {
        "resourceType": "MedicationRequest",
        "id": "eN.alBGRZHgxpRetMTYX0oe0gd9bOftoNapc5GwQf6Ng3",
        "identifier": [
            {
                "use": "usual",
                "system": "urn:oid:1.2.840.114350.1.13.266.2.7.2.798268",
                "value": "531456597"
            }
        ],
        "status": "active",
        "intent": "order",
        "category": [
            {
                "coding": [
                    {
                        "system": "http://terminology.hl7.org/CodeSystem/medicationrequest-category",
                        "code": "community",
                        "display": "Community"
                    }
                ],
                "text": "Community"
            }
        ],
        "medicationReference": {
            "reference": "Medication/eUbrTEqiegyjudiMltNfVuqcsLmZEs88QR5Ju.wQbMCxH-OAahwAk1WjkVlU1VxRlhqN3GTFgId2mhvH-n6k-jrh7OAQCiRoK16YlLG2anMc3",
            "display": "metoprolol succinate (TOPROL-XL) 24 hr tablet"
        },
        "subject": {
            "reference": "Patient/eeLXYHAezDPbPVhPeYwanRDH-YRMo3Jq3tWYaFkU2VXc3",
            "display": "Sotiriou, Celia Perez"
        },
        "encounter": {
            "reference": "Encounter/e4Cv.TnfZJATduP8V4pp1QA3",
            "display": "Refill"
        },
        "authoredOn": "2023-06-07",
        "requester": {
            "reference": "Practitioner/e5fVfo0xCCXIWn8K6xmU14Q3",
            "type": "Practitioner",
            "display": "Radhika Anusha Ramanan, MD"
        },
        "recorder": {
            "reference": "Practitioner/e5fVfo0xCCXIWn8K6xmU14Q3",
            "type": "Practitioner",
            "display": "Radhika Anusha Ramanan, MD"
        },
        "courseOfTherapyType": {
            "coding": [
                {
                    "system": "http://terminology.hl7.org/CodeSystem/medicationrequest-course-of-therapy",
                    "code": "acute",
                    "display": "Short course (acute) therapy"
                }
            ],
            "text": "Short course (acute) therapy"
        },
        "note": [
            {
                "text": "PT REQUESTS REFILLS FOR METOPROLOL"
            }
        ],
        "dosageInstruction": [
            {
                "text": "TAKE 1 TABLET BY MOUTH EVERY DAY. GENERIC FOR TOPROL XL., Normal",
                "patientInstruction": "TAKE 1 TABLET BY MOUTH EVERY DAY. GENERIC FOR TOPROL XL.",
                "timing": {
                    "repeat": {
                        "boundsPeriod": {
                            "start": "2023-06-07"
                        }
                    }
                }
            }
        ],
        "dispenseRequest": {
            "validityPeriod": {
                "start": "2023-06-07"
            },
            "numberOfRepeatsAllowed": 3,
            "quantity": {
                "value": 90,
                "unit": "tablet"
            }
        },
        "priorPrescription": {
            "reference": "MedicationRequest/ep-001Z7e0tygmM5bDdg2.hiRQTFyqqJV6JQdSY-K8Hk3",
            "display": "metoprolol succinate (TOPROL-XL) 200 mg 24 hr tablet"
        }
    }
]

export { sampleMedications }