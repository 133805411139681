import React from "react";
import './Header.scss';
import { dateWithTimeString } from "./shared/utils";

export function Header(props) {
  return (
    <div id="Header">
      <div className="background-row">
        <div className="left column">
          <div className="info-row-1">
            <span className="patient-name">{props.patientLastName}, {props.patientFirstName}</span>
          </div>
          <div className="info-row-2">
            <span id="patient-dob" className="label">DOB</span>
            <span id="patient-dob" className="value">{props.dob} ({props.age} years old)</span>
            <span id="patient-mrn" className="label">MRN</span>
            <span id="patient-mrn" className="value">{props.mrn}</span>
          </div>
        </div>
        <div className="right column">
          <div className="row">
            <button onClick={props.handleSubmitFeedback}>Submit Feedback</button>
            <button onClick={() => props.handleRefresh(false)}>Refresh</button>
            <p>{`Last updated: ${dateWithTimeString(props.updateDate)}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}