import React, { useState } from "react";
import "./DropDownBox.scss";

function handleClick(checkedValue, localHandler, externalHandler) {
  localHandler(checkedValue);
  if (externalHandler) {
    externalHandler();
  }
}

export function DropDownBox(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="DropDownBox"onClick={()=>handleClick(!isOpen, setIsOpen, props.handler)}>
      <div className="dbox-container" >
        { isOpen ?
          <h4 className="caret">∧</h4>
          :
          <h4 className="caret">∨</h4>
        }
      </div>
      <span>{props.label}</span>
    </div>
  );
}
