import React, { useState } from "react";
import { DropDownBox } from "./DropDownBox";
import './OtherSuggestions.scss';
import { logUsage } from './shared/utils.js'
import { OK } from "./shared/constants";

export default function OtherSuggestions (props) {

  const [phone, setPhone] = useState(props.phone);
  const [showDietInstructions, setShowDietInstructions] = useState(false);
  const [showHomeBPInstructions, setShowHomeBPInstructions] = useState(false);
  const [showHomeMonitoring, setShowHomeMonitoring] = useState(false);
  const [showOrthostatic, setShowOrthostatic] = useState(false);
  const [showScheduleVisit, setShowScheduleVisit] = useState(false);
  const [showDiagnosisOk, setShowDiagnosisOk] = useState(false);

  const handlePhoneChange = (event) => {
    setPhone(event.target.value)
  }

  const handlePhoneClick = (event) => {
    event.preventDefault();
  }

  const handleShowHomeBPInstructions = () => {
    if (!showHomeBPInstructions)
      logUsage(props.env, props.userId, props.patientMRN, props.encounter,  "ShowHomeBPInstructions");
    setShowHomeBPInstructions(!showHomeBPInstructions);
  }

  const handleShowDietInstructions = () => {
    if (!showDietInstructions)
      logUsage(props.env, props.userId, props.patientMRN, props.encounter,  "ShowDietInstructions");
    setShowDietInstructions(!showDietInstructions);
  }

  return (
    <div className="OtherSuggestions">
      <div className="checkbox-list">
        <p className="reco-title">Order Checklist</p>
        <div className="with-textbox box-spacer">
          <DropDownBox borderStyle="no-border" label="Recommend home BP monitoring" name="home-monitoring" handler={() => setShowHomeMonitoring(!showHomeMonitoring)}/>
          { showHomeMonitoring && 
          <div onClick={handlePhoneClick}>

          <form onClick={handlePhoneClick}>
              <input className="phone" type="text" name="phone" value={phone} onChange={handlePhoneChange} />
          </form>
          </div>
          }
        </div>
        { props.recommendations.diagnosis === OK &&
          <div className="box-spacer">
            <DropDownBox label="Reassess in 3-6 months." name="diagnosisOk"  handler={() => setShowDiagnosisOk(!showDiagnosisOk)} />
            { showOrthostatic &&
              <p>
                Consider a follow up appointment in 3-6 months to reassess.
              </p>
            }
          </div>
        }
        
        { props.age > 65 &&
          <div className="box-spacer">
            <DropDownBox label="Assess for orthostatic hypotension given age > 65" name="hypotension" handler={() => setShowOrthostatic(!showOrthostatic)}/>
            { showOrthostatic &&
              <p>
                Consider checking orthostatic BP numbers considering risk of orthostatic hypotension in older adults. 
              </p>
            }
          </div>
        }
        { props.recommendations.diagnosis !== OK &&
          <div className="box-spacer">
            <DropDownBox label="Schedule visit in 2-4 weeks and reassess" name="reassess" handler={()=> setShowScheduleVisit(!showScheduleVisit)} />
            { showScheduleVisit &&
              <p>
                Consider a follow up appointment in 3-6 months to reassess.
              </p>
            }
          </div>
        }
        <div className="box-spacer">
          <DropDownBox label="Diet Instructions" name="diet-instructions" handler={handleShowDietInstructions}/>
          { showDietInstructions &&
            <p>
              Consider encouragement of diet/lifestyle changes. Consider using the following dot phrase for patient instructions: “.ENGAGEDIET”
            </p>
          }
        </div>
        <div className="box-spacer">
          <DropDownBox label="Home BP Instructions" name="metabolic" handler={handleShowHomeBPInstructions}/>
          { showHomeBPInstructions &&
            <p>
              Consider use of BP values to assess patient. Consider using the following dot phrase  for patient instructions: “.ENGAGEHOMEBP”
            </p>
          }
        </div>
      </div>
    </div>
  )   
}