import {
  hasItemsInCommon,
  getEarliestDataDate,
  CKD,
  CHF,
  convertWordToNumber,
  isLocalhost,
  searchUrlParams,
} from "./shared/utils.js";
import { CRX_LAB_LOINCS_SET, INR_LOINC } from "./shared/constants.js";
import { sampleMedications } from "./data/medication_without_dosage.js";
import { sampleBPMeasurements } from "./data/clinic_bp_measurements.js";
import CRX_LABS from "./data/cirrhosis_labs.json";

var dayjs = require("dayjs");

// const LOCAL_OBSERVATION='https://engagerx.ucsf.edu/api/observations';
// const LOCAL_PATIENT='https://engagerx.ucsf.edu/api/patient';
// const LOCAL_MEDICATION='https://engagerx.ucsf.edu/api/medications';
// const LOCAL_CONDITION='https://engagerx.ucsf.edu/api/conditions';
// const LOCAL_ALLERGY='https://engagerx.ucsf.edu/api/allergies';

let LOCAL_OBSERVATION = "/api/observations";
let LOCAL_PATIENT = "/api/patient";
let LOCAL_MEDICATION = "/api/medications";
let LOCAL_CONDITION = "/api/conditions";
let LOCAL_ALLERGY = "/api/allergies";
// let LOCAL_PROCEDURE = "/api/procedures";
// let LOCAL_NUTRITION_ORDERS = "/api/nutrition-orders";
// let LOCAL_DIAGNOSTIC_REPORTS = "/api/diagnostic-reports";

if (isLocalhost) {
  LOCAL_OBSERVATION = "http://localhost:8081/api/observations";
  LOCAL_PATIENT = "http://localhost:8081/api/patient";
  LOCAL_MEDICATION = "http://localhost:8081/api/medications";
  LOCAL_CONDITION = "http://localhost:8081/api/conditions";
  LOCAL_ALLERGY = "http://localhost:8081/api/allergies";
  // LOCAL_PROCEDURE = "http://localhost:8081/api/procedures";
  // LOCAL_NUTRITION_ORDERS = "http://localhost:8081/api/nutrition-orders";
  // LOCAL_DIAGNOSTIC_REPORTS = "http://localhost:8081/api/diagnostic-reports";
}

const MILLIS_IN_A_DAY = 86400000;

const hardcodedMrn = searchUrlParams("hardcode");

const HYPERTENSION_LAB_LOIN_CODES = new Set([
  "17856-6", // Hemoglobin
  "2160-0", // Creatinine
  "57698-3", // Lipid panel
  "2093-3", // Cholesterol in serum or plasma
  "2571-8", // Triglyceride in serum or plasma
  "2085-9", // Cholesterol in HDL in serum or plasma
  "18262-6", // Cholesterol in LDL in serum or plasma
  "13458-5", // Cholesterol in VLDL in serum or plasma
  "13457-7", // LDL
  "2951-2", // Sodium in serum/plasma
  "33914-3", // eGFR
]);

const CKD_CODES = [
  "D63.1",
  "E08.22",
  "E09.22",
  "E10.22",
  "E11.22",
  "E13.22",
  "I12",
  "I13",
  "N18",
  "O10.2",
  "O10.3",
  "Q61",
];
const CHF_CODES = ["I09.81", "I11.0", "I13.0", "I13.2", "I50", "I97.13"];

// Override data from Url query parameters:
let queryMeds = searchUrlParams("medications");
if (queryMeds) {
  queryMeds = queryMeds.split("|");
}

let queryTabs = searchUrlParams("tabs");
if (queryTabs) {
  queryTabs = Number(queryTabs);
}

let queryConds = searchUrlParams("conditions");
if (queryConds) {
  queryConds = queryConds.split("|");
}

let querySbp = searchUrlParams("sbp");
if (querySbp) {
  querySbp = querySbp.split(",");
}

let queryDbp = searchUrlParams("dbp");
if (queryDbp) {
  queryDbp = queryDbp.split(",");
}

let queryEgfr = searchUrlParams("egfr");
if (queryEgfr) {
  queryEgfr = queryEgfr.split("|");
}

let queryAllergies = searchUrlParams("allergy");
if (queryAllergies) {
  queryAllergies = queryAllergies.split("|");
}

const samplePatient = searchUrlParams("samplePatient");

const getLoinCodes = (client, obv) => {
  var codes = new Set();
  if (client) codes.add(client.getPath(obv, "code.coding.0.code"));
  else if (obv.code && obv.code.coding) {
    obv.code.coding.forEach((codeCandidate) => {
      if (codeCandidate.system === "http://loinc.org")
        codes.add(codeCandidate.code);
    });
  } else if (obv.code) codes.add(obv.code.text);

  return codes;
};

const getObservationOrder = (code) => {
  let order;
  switch (code) {
    case "17856-6": // Hemoglobin
      order = 0.51;
      break;
    case "2571-8": // Triglyceride in serum or plsma
      order = 0.52;
      break;
    case "2160-0": // Creatinine
      order = 0.45;
      break;
    case "13457-7": // LDL
      order = 0.8;
      break;
    case "2093-3": // Cholesterol in serum or plasma
    case "2085-9": // Cholesterol in HDL in serum or plasma
    case "18262-6": // Cholesterol in LDL in serum or plasma
    case "13458-5": // Cholesterol in VLDL in serum or plasma
      order = 0.9;
      break;
    default:
      order = 0.5;
  }
  return order;
};

const processObservationValue = (obv) => {
  const observationData = {};
  observationData["referenceRange"] = obv.referenceRange;
  observationData["date"] = obv.effectiveDateTime;
  if (obv.valueQuantity) {
    observationData["value"] = obv.valueQuantity.value;
    observationData["unit"] = obv.valueQuantity.unit;
  } else if (obv.valueString) {
    observationData["value"] = Number(
      obv.valueString.replace(">", "").replace("<", "")
    );
    observationData["displayValue"] = obv.valueString;
  }
  observationData["order"] = 0.5;
  return observationData;
};

const extractMeasurementsFromObservations = (client, observations) => {
  const datedBPMeasurements = {};
  const datedEGFRMeasurements = {};
  const datedACRMeasurements = {};
  const datedLVEFMeasurements = {};
  const datedSaltMeasurements = {};
  const datedSmokingMeasurements = {};
  const datedOtherMeasurements = {};
  const datedCRXMeasurements = {};
  // Structure of observations differs between public sandbox
  // and the proxied UCSF one
  const observationList = observations.observations || observations;
  let index = 0;
  observationList.forEach((obv) => {
    index = index + 1;
    var observationCodes = getLoinCodes(client, obv);
    const commonItem = hasItemsInCommon(CRX_LAB_LOINCS_SET, observationCodes);

    // Handle CRX Lab Codes
    if (obv.valueQuantity || commonItem) {
      //codes.push(obv.code.coding)
      if (commonItem) {
        // Check for blood pressure observation. It's a special case
        // with two components in it. This should be refactored to dry
        // up this code along with the 55284-4 code lower below
        if (observationCodes.has("55284-4")) {
          obv.component.forEach((part) => {
            const observationData = processObservationValue(obv);
            if (obv.valueQuantity) {
              observationData["value"] = obv.valueQuantity.value;
              observationData["unit"] = obv.valueQuantity.unit;
            }
            const code = client
              ? client.getPath(part, "code.coding.0.code")
              : part.code.coding[0].code;
            let name;
            if (code === "8480-6") {
              name = "systolic";
            } else if (code === "8462-4") {
              name = "diastolic";
            }
            observationData["loinc"] = code;
            observationData["value"] = Math.round(part.valueQuantity.value);
            observationData["unit"] = part.valueQuantity.unit;
            observationData["type"] = name;
            datedCRXMeasurements[obv.effectiveDateTime + name] =
              observationData;
          });
        } else if (obv.valueQuantity) {
          const observationData = processObservationValue(obv);
          observationData.order = getObservationOrder(commonItem);
          observationData["type"] = obv.code.text;
          if (commonItem === "34714-6") {
            observationData["loinc"] = INR_LOINC;
          } else {
            observationData["loinc"] = commonItem;
          }
          if (CRX_LABS[observationData["loinc"]]) {
            observationData["section"] =
              CRX_LABS[observationData["loinc"]].section;
          }
          datedCRXMeasurements[obv.effectiveDateTime + obv.code.text] =
            observationData;
        }
      }
    }

    if (
      observationCodes.has("33914-3") ||
      observationCodes.has("48642-3") ||
      observationCodes.has("48643-1")
    ) {
      // Effective glomerular filtration rate
      const observationData = processObservationValue(obv);
      if (observationData.unit)
        observationData.unit = observationData.unit.replace("/{1.73_m2}", "");
      observationData["type"] = "eGFR";
      observationData["loinc"] = "33914-3";
      observationData["order"] = 0.48;
      datedEGFRMeasurements[obv.effectiveDateTime + "egfr"] = observationData;
    } else if (observationCodes.has("72166-2")) {
      // console.log(obv)
      // Smoking status
      const observationData = {};
      observationData["date"] = obv.effectiveDateTime;
      observationData["value"] = obv.valueCodeableConcept.text;
      observationData["type"] = "Smoking";
      datedSmokingMeasurements[obv.effectiveDateTime + "smoking"] =
        observationData;
    } else if (
      observationCodes.has("14959-1") ||
      observationCodes.has("9318-7")
    ) {
      //console.log(obv)
      // Microalbumin creatinine ratio
      const observationData = processObservationValue(obv);
      observationData["type"] = "Microalbuminin Creatinine Ratio";
      observationData.order = 0.45;
      datedACRMeasurements[obv.effectiveDateTime + "acr"] = observationData;
    } else if (
      observationCodes.has("6298-4") ||
      observationCodes.has("2823-3")
    ) {
      const observationData = processObservationValue(obv);
      observationData["type"] = "Potassium";
      observationData["loinc"] = "2823-3";
      observationData.order = 0.2;
      datedSaltMeasurements[obv.effectiveDateTime + "potassium"] =
        observationData;
    } else if (
      observationCodes.has("2947-0") ||
      observationCodes.has("2951-2")
    ) {
      const observationData = processObservationValue(obv);
      observationData.order = 0.1;
      observationData["type"] = "Sodium";
      observationData["loinc"] = "2951-2";
      datedSaltMeasurements[obv.effectiveDateTime + "sodium"] = observationData;
    } else if (observationCodes.has("10230-1")) {
      const observationData = processObservationValue(obv);
      observationData["loinc"] = "10230-1";
      datedLVEFMeasurements[obv.effectiveDateTime + "lvef"] = observationData;
      //} else if (observationCode === "8716-3" || observationCode === "55284-4") {
    } else if (observationCodes.has("55284-4")) {
      //console.log(obv)
      obv.component.forEach((part) => {
        // Blood pressure
        const code = client
          ? client.getPath(part, "code.coding.0.code")
          : part.code.coding[0].code;
        let observationData = null;
        if (obv.effectiveDateTime in datedBPMeasurements) {
          observationData = datedBPMeasurements[obv.effectiveDateTime];
        } else {
          observationData = {};
          datedBPMeasurements[obv.effectiveDateTime] = observationData;
          observationData["date"] = obv.effectiveDateTime;
        }

        // observationData["referenceRange"] = obv.referenceRange;
        // observationData["date"] = obv.effectiveDateTime;
        // if (obv.valueQuantity) {
        //   observationData["value"] = obv.valueQuantity.value;
        //   observationData["unit"] = obv.valueQuantity.unit;

        if (code === "8480-6") {
          observationData["systolic"] = Math.round(part.valueQuantity.value);
          observationData["loinc"] = "8480-6";
        } else if (code === "8462-4") {
          observationData["loinc"] = "8462-4";
          observationData["diastolic"] = Math.round(part.valueQuantity.value);
        }
      });
    } else {
      if (obv.valueQuantity) {
        //codes.push(obv.code.coding)
        const commonItem = hasItemsInCommon(
          HYPERTENSION_LAB_LOIN_CODES,
          observationCodes
        );
        if (commonItem) {
          console.log("Common item is:", commonItem);
          const observationData = processObservationValue(obv);
          observationData.order = getObservationOrder(commonItem);
          observationData["type"] = obv.code.text;
          observationData["loinc"] = commonItem;
          datedOtherMeasurements[obv.effectiveDateTime + obv.code.text] =
            observationData;
        }
      }
    }
    //console.log("completed processing observation: ", obv, index)
  });
  console.log("Completed retrieving all observations");

  const datedLabMeasurements = {
    ...datedSaltMeasurements,
    ...datedACRMeasurements,
    ...datedEGFRMeasurements,
    ...datedOtherMeasurements,
  };
  const sortedLabMeasurements = [];
  Object.keys(datedLabMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedLabMeasurements.push(datedLabMeasurements[key]);
    });

  const sortedBPMeasurements = [];
  Object.keys(datedBPMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedBPMeasurements.push(datedBPMeasurements[key]);
    });

  const sortedEGFRMeasurements = [];
  Object.keys(datedEGFRMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedEGFRMeasurements.push(datedEGFRMeasurements[key]);
    });

  const sortedACRMeasurements = [];
  Object.keys(datedACRMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedACRMeasurements.push(datedACRMeasurements[key]);
    });

  const sortedLVEFMeasurements = [];
  Object.keys(datedLVEFMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedLVEFMeasurements.push(datedLVEFMeasurements[key]);
    });

  const sortedSaltMeasurements = [];
  Object.keys(datedSaltMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedSaltMeasurements.push(datedSaltMeasurements[key]);
    });

  const sortedCRXMeasurements = [];
  Object.keys(datedCRXMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedCRXMeasurements.push(datedCRXMeasurements[key]);
    });

  const sortedOtherMeasurements = [];
  Object.keys(datedOtherMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedOtherMeasurements.push(datedOtherMeasurements[key]);
    });

  const smokingStatus =
    datedSmokingMeasurements[
      Object.keys(datedSmokingMeasurements).sort().reverse()[0]
    ];

  const homeBPData = observations.homeBPData || [];
  console.log("About to process flow sheet observations");
  addFlowSheetDatatoHomeBPData(
    homeBPData,
    observations.flowSheetObservationData || []
  );
  console.log("Done processing flow sheet observations");

  console.log("Sorted BP Measurements are:", sortedBPMeasurements);
  const measurements = {
    bpMeasurements: sortedBPMeasurements,
    acrMeasurements: sortedACRMeasurements,
    lvefMeasurements: sortedLVEFMeasurements,
    saltMeasurements: sortedSaltMeasurements,
    labMeasurements: sortedLabMeasurements,
    egfrMeasurements: sortedEGFRMeasurements,
    otherMeasurements: sortedOtherMeasurements,
    crxMeasurements: sortedCRXMeasurements,
    homeBpMeasurements: homeBPData,
    smokingStatus: smokingStatus ? smokingStatus.value : "Unknown",
  };

  return measurements;
};

const addFlowSheetDatatoHomeBPData = (homeBPData, flowSheetObservationData) => {
  const timestampedMeasurements = {};
  //console.log("FlowSheet data NOW is", flowSheetObservationData);
  // Grab all systolics and diastolics
  flowSheetObservationData.forEach((obv) => {
    if (
      obv.code &&
      (obv.code.text === "Diastolic Blood Pressure" ||
        obv.code.text === "Systolic Blood Pressure" ||
        obv.code.text.startsWith("Systolic BP") ||
        obv.code.text.startsWith("Diastolic BP")
      )
    ) {
      if (!(obv.effectiveDateTime in timestampedMeasurements)) {
        timestampedMeasurements[obv.effectiveDateTime] = {};
      }
      if (obv.code.text === "Systolic Blood Pressure" ||
        obv.code.text.startsWith("Systolic BP")) {
        timestampedMeasurements[obv.effectiveDateTime].systolic = Math.round(
          obv.valueQuantity.value
        );
      } else if (obv.code.text === "Diastolic Blood Pressure" ||
        obv.code.text.startsWith("Diastolic BP")) {
        timestampedMeasurements[obv.effectiveDateTime].diastolic = Math.round(
          obv.valueQuantity.value
        );
      }
    }
  });

  const datedMeasurements = {};
  Object.keys(timestampedMeasurements).forEach((timestamp) => {
    const obvDate = dayjs(timestamp).format("YYYY-MM-DD");
    const measurement = timestampedMeasurements[timestamp];
    if (!(obvDate in datedMeasurements)) {
      datedMeasurements[obvDate] = { date: obvDate, measurements: [] };
    }
    measurement.date = obvDate;
    datedMeasurements[obvDate].measurements.push(measurement);
  });

  // Ignore time. Only use dates for keys;
  const sortedMeasurements = [];
  Object.keys(datedMeasurements)
    .sort()
    .reverse()
    .forEach(function (key) {
      sortedMeasurements.push(datedMeasurements[key]);
    });
  homeBPData.push(...sortedMeasurements);
};

const addBPsFromQuery = (measurements, querySbp, queryDbp) => {
  const numEntries = Math.max(querySbp.length, queryDbp.length);
  const startDate = new Date().getTime();
  const pointList = [];
  for (let i = 0; i < numEntries; i++) {
    const measurementDate = new Date(startDate - MILLIS_IN_A_DAY * i * 2); // Every other day
    const point = {
      date: measurementDate.toISOString(),
      systolic: querySbp[i],
      diastolic: queryDbp[i],
    };
    console.log("Adding point");
    console.log(point);
    pointList.push(point);
  }
  measurements.bpMeasurements.unshift(...pointList);

  console.log("Now bp measurements are:");
  console.log(measurements.bpMeasurements);
};

const extractMedicationsFromQuery = (queryMeds, queryTabs) => {
  console.log("Rewriting meds!!");
  const medications = [];
  queryMeds.forEach((med) => {
    const medObj = {};
    medObj["authoredOn"] = "2019-08-02T12:12:12-04:00";
    medObj["displayName"] = med;
    medObj["quantity"] = queryTabs;
    medObj["status"] = "active";
    medObj["dosageInstruction"] = {};
    medications.push(medObj);
  });
  return medications;
};

const extractAllergiesFromFHIR = (allergyIntolerances) => {
  console.log(allergyIntolerances);
  const allergyList = [];
  allergyIntolerances.forEach((allergy) => {
    if (
      allergy.code &&
      allergy.code.text &&
      allergy.category &&
      allergy.category.includes("medication")
    ) {
      allergyList.push(allergy.code.text);
    }
  });
  //console.log("processed allergens");
  //console.log(allergyList);
  return allergyList;
};

const getDosage = (instruction) => {
  const dosage = {};
  instruction.extension.forEach((extension) => {
    if (
      extension.url === "https://open.epic.com/fhir/extensions/ordered-dose" &&
      extension.valueQuantity
    ) {
      dosage.amount = extension.valueQuantity.value;
      dosage.unit = extension.valueQuantity.unit;
      console.log("set dosage to:", dosage);
    }
  });
  return dosage;
};

const parseQuantity = (text) => {
  const lowerText = text.toLowerCase();
  var qty = 1;
  var qtyRegex = /\b([^\s]+)\s+(tablet|pill|capsule|drop)/;
  const matches = lowerText.match(qtyRegex);
  if (matches) {
    const tmpQty = matches[1];
    try {
      qty = Number(tmpQty);
    } catch (error) {
      qty = convertWordToNumber(tmpQty);
      if (isNaN(qty)) {
        // This is hacky, but have to come up with a number at some point!
        qty = 1;
      }
    }
  }
  //console.log("Found qty in dosageInstruction", text, qty);
  return qty;
};

const parseTimesDaily = (text) => {
  let timesDaily = 1;
  const lowerText = text.toLowerCase();
  if (
    lowerText.indexOf("twice daily") > -1 ||
    lowerText.indexOf("twice a day") > -1
  ) {
    timesDaily = 2;
  } else if (lowerText.indexOf(" times ") > 0) {
    // The below code is nice, but does not work on IE 11, hence the stupid string parsing
    /* 
      const matcher = lowerText.matchAll(/(\d+) times/g);
      for (const match of matcher) {
        timesDaily = match[1]
      }
      */

    // Grab all the words before the " times ", Find the last one that looks like a number
    const endIndex = lowerText.indexOf(" times ");
    const words = lowerText.slice(0, endIndex).split(" ");
    words.forEach((word) => {
      if (!isNaN(word)) {
        timesDaily = parseInt(word);
      }
    });
  }
  //console.log("Got this daily times amount", timesDaily)
  return timesDaily;
};

const extractMedicationsFromFHIR = (medications) => {
  // First grab all the MedicationResources and make them searchable by id
  const medicationResourceMap = {};
  medications
    .filter((med) => med.resourceType === "Medication")
    .forEach((medResource) => {
      medicationResourceMap[medResource.id] = medResource;
    })

  // Now process the MedicationRequests
  const medicationList = [];
  medications
    .filter((med) => med.resourceType === "MedicationRequest")
    .forEach((med) => {
      const cleanedMedication = { status: "inactive", isDrop: false };
      //console.log("incoming active med is", med);
      var foundStructuredQuantity = false;
      var alternateQuantityFromText = 1;
      cleanedMedication["timesDaily"] = 1;
      cleanedMedication["displayName"] = med.medicationCodeableConcept
        ? med.medicationCodeableConcept.coding[0].display
        : med.medicationReference.display;
      //console.log("Got a medication!", med, cleanedMedication["displayName"]);
      if (med.dosageInstruction && med.dosageInstruction[0].doseAndRate) {
        if (
          med.dosageInstruction[0].text &&
          med.dosageInstruction[0].text.indexOf("as needed") !== -1
        ) {
          cleanedMedication["prn"] = true;
        }
        cleanedMedication["quantity"] = 1;
        med.dosageInstruction[0].doseAndRate.forEach((doseAndRate) => {
          if (doseAndRate.doseQuantity) {
            //console.log("a doseAndRate is", doseAndRate);
            if (doseAndRate.doseQuantity.unit === "mg") {
              cleanedMedication["dosageAmount"] =
                doseAndRate.doseQuantity.value;
              cleanedMedication["dosageUnit"] = "mg";
            } else if (
              doseAndRate.doseQuantity &&
              (doseAndRate.doseQuantity.unit === "tablet" ||
                doseAndRate.doseQuantity.unit === "capsule" ||
                doseAndRate.doseQuantity.unit === "pill")
            ) {
              cleanedMedication["quantity"] = doseAndRate.doseQuantity.value;
              foundStructuredQuantity = true;
            } else if (
              doseAndRate.doseQuantity &&
              doseAndRate.doseQuantity.unit === "drop"
            ) {
              cleanedMedication["quantity"] = doseAndRate.doseQuantity.value;
              cleanedMedication["isDrop"] = true;
              foundStructuredQuantity = true;
            }
          }
        });

        if (
          cleanedMedication["quantity"] &&
          cleanedMedication["dosageAmount"]
        ) {
          cleanedMedication["dosageAmount"] /= cleanedMedication["quantity"];
        }
      } else if (med.dosageInstruction && med.dosageInstruction[0].extension) {
        const dosage = getDosage(med.dosageInstruction[0]);
        cleanedMedication["dosageAmount"] = dosage.amount;
        cleanedMedication["dosageUnit"] = dosage.unit;
      }

      if (med.dosageInstruction && med.dosageInstruction[0].text) {
        //console.log("Got dosage instructions");
        cleanedMedication["timesDaily"] = parseTimesDaily(
          med.dosageInstruction[0].text
        );
        alternateQuantityFromText = parseQuantity(
          med.dosageInstruction[0].text
        );
      }
      if (!foundStructuredQuantity) {
        cleanedMedication["quantity"] = alternateQuantityFromText;
        console.log("Overriding qty with", alternateQuantityFromText);
      }
      cleanedMedication["status"] = med.status;

      // Ok, if we still don't have a dosage, let's go look at the Medication resource
      if (!med.dosageAmount || !med.dosageUnit) {
        updateMedicationWithDosageFromMedicationResource(
          cleanedMedication,
          med,
          medicationResourceMap
        );
      }

      medicationList.push(cleanedMedication);
      //console.log("Cleaned medication is", cleanedMedication)
    });
  console.log("Done cleaning all medications", medicationList);
  return medicationList;
};

const updateMedicationWithDosageFromMedicationResource = (
  cleanedMedication,
  med,
  medicationResources
) => {
  if (!med.medicationReference || !med.medicationReference.reference) {
    return
  }
  const medicationResourceId = med.medicationReference.reference.split("/")[1];
  const medicationResource = medicationResources[medicationResourceId];
  // TODO: Deal with multi ingredient medications
  if (medicationResource.ingredient && medicationResource.ingredient[0].strength) {
    const numerator = medicationResource.ingredient[0].strength.numerator;
    const denominator = medicationResource.ingredient[0].strength.denominator;
    // If units are the same, then just pick the numerator. Otherwise, divide
    if (numerator.unit === denominator.unit) {
      cleanedMedication["dosageAmount"] = numerator.value;
      cleanedMedication["dosageUnit"] = numerator.unit;
    } else {
      cleanedMedication["dosageAmount"] = numerator.value / denominator.value;
      cleanedMedication["dosageUnit"] = `${numerator.unit}/${denominator.unit}`;
    }
  }
};

const extractConditionsFromQuery = (queryConds) => {
  console.log("Rewriting conditions!!");
  const conditions = [];
  queryConds.forEach((cond) => {
    const condObj = {};
    condObj["recordedDate"] = "2019-08-02T12:12:12-04:00";
    condObj["name"] = cond;
    conditions.push(condObj);
  });
  return conditions;
};

const parseConditionCode = (codes) => {
  var conditionType = null;
  codes.forEach((code) => {
    CKD_CODES.forEach((ckd_code) => {
      if (code.code === ckd_code || code.code.startsWith(ckd_code + ".")) {
        console.log("COND: found CKD");
        conditionType = CKD;
      }
    });
    CHF_CODES.forEach((chf_code) => {
      if (code.code === chf_code || code.code.startsWith(chf_code + ".")) {
        console.log("COND: found CHF");
        conditionType = CHF;
      }
    });
  });
  return conditionType;
};

// const extractProceduresFromFHIR = (procedures) => {
//   const procedureList = [];
//   procedures.forEach((procedure) => {
//     if (procedure.code) {
//       const procedureObj = {};
//       if (procedure.code.text) {
//         procedureObj.name = procedure.code.text;
//         procedureObj.status = procedure.status;
//         procedureObj.date = procedure.performedDateTime;
//         procedureList.push(procedureObj);
//       }
//     }
//   });
//   return procedureList;
// };

const extractConditionsFromFHIR = (conditions) => {
  const conditionList = [];
  conditions.forEach((condition) => {
    if (condition.code) {
      if (condition.code.text.toLowerCase().endsWith("pregnancy")) {
        console.log("Was pregnant");
        if (
          condition.clinicalStatus.coding &&
          condition.clinicalStatus.coding[0].code === "resolved"
        ) {
          console.log("Resolved");
        } else {
          const relevantCondition = {};
          relevantCondition["recordedDate"] = condition["recordedDate"];
          relevantCondition["name"] = condition.code.text;
          conditionList.push(relevantCondition);
        }
      } else if (
        condition.code.text.toLowerCase().indexOf("chronic kidney disease") !==
        -1
      ) {
        const relevantCondition = {};
        relevantCondition["recordedDate"] = condition["recordedDate"];
        relevantCondition["name"] = condition.code.text;
        conditionList.push(relevantCondition);
      } else if (condition.code.text.toLowerCase().startsWith("myocardial")) {
        const relevantCondition = {};
        relevantCondition["recordedDate"] = condition["recordedDate"];
        relevantCondition["name"] = condition.code.text;
        conditionList.push(relevantCondition);
      } else if (condition.code.text.toLowerCase().startsWith("albuminuria")) {
        const relevantCondition = {};
        relevantCondition["recordedDate"] = condition["recordedDate"];
        relevantCondition["name"] = condition.code.text;
        conditionList.push(relevantCondition);
      } else if (condition.code.text.toLowerCase().startsWith("diabetes")) {
        const relevantCondition = {};
        relevantCondition["recordedDate"] = condition["recordedDate"];
        relevantCondition["name"] = condition.code.text;
        conditionList.push(relevantCondition);
        // } else if  (condition.code.text.toLowerCase().indexOf('congestive heart failure') !== -1) {
        //   const relevantCondition = {}
        //   relevantCondition['recordedDate'] = condition['recordedDate'];
        //   relevantCondition['name'] = condition.code.text;
        //   conditionList.push(relevantCondition)
      } else if (condition.code.coding) {
        const conditionType = parseConditionCode(condition.code.coding);
        if (conditionType) {
          const relevantCondition = {};
          relevantCondition["name"] = conditionType;
          relevantCondition["recordedDate"] = condition["recordedDate"];
          conditionList.push(relevantCondition);
        }
      }
    }
  });
  return conditionList;
};

const extractEGFRFromQuery = (queryEgfr) => {
  console.log("Rewriting egfr!!");
  const egfrs = [];
  queryEgfr.forEach((egfr) => {
    const egfrObj = {};
    egfrObj["recordedDate"] = "2019-08-02T12:12:12-04:00";
    egfrObj["value"] = parseInt(egfr);
    egfrs.push(egfrObj);
  });
  return egfrs;
};

const getPatient = (
  patientId,
  accessToken,
  env,
  mrn,
  useCache,
  setStatuses
) => {
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");
  const url = `${LOCAL_PATIENT}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${mrn}&useCache=${useCache}`;
  console.log("Patient URL is", url);
  const promise = fetch(url, {
    cache: "no-store",
    method: "GET",
  })
    .catch((error) => {
      console.error("Error:", error);
      Promise.reject("Error getting patient data");
    })
    .then((response) => {
      //console.log("Got Patient response. It be", response);
      return response.json();
    })
    .then((data) => {
      if (setStatuses) {
        setStatuses("complete", null, null, null, null);
      }
      return [data]; // List to make it look like the client call. #TODO remove this.
    });
  return promise;
};

const getMedications = (
  patientId,
  patientMRN,
  accessToken,
  env,
  useCache,
  setStatuses
) => {
  const url = `${LOCAL_MEDICATION}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}&useCache=${useCache}`;
  const promise = fetch(url, {
    cache: "no-store",
    method: "GET",
  })
    .catch((error) => console.error("Error:", error))
    .then((response) => response.json())
    .then((data) => {
      //console.log("Got Medication data. It be", data)
      if (setStatuses) {
        setStatuses(null, "complete", null, null, null);
      }
      return data;
    });
  return promise;
};

const getConditions = (
  patientId,
  patientMRN,
  accessToken,
  env,
  encounter,
  useCache,
  setStatuses
) => {
  const url = `${LOCAL_CONDITION}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}&encounter=${encounter}&useCache=${useCache}`;
  const promise = fetch(url, {
    cache: "no-store",
    method: "GET",
  })
    .catch((error) => console.error("Error:", error))
    .then((response) => response.json())
    .then((data) => {
      //console.log("Got Condition data. It be", data)
      if (setStatuses) {
        setStatuses(null, null, "complete", null, null);
      }
      return data;
    });
  return promise;
};

// const getProcedures = (
//   patientId,
//   patientMRN,
//   accessToken,
//   env,
//   encounter,
//   useCache,
//   application,
// ) => {
//   if (application !== CIRRHOSIS_APP) {
//     console.log("In getProcedures, returning empty ones");
//     return Promise.resolve([]);
//   }

//   const url = `${LOCAL_PROCEDURE}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}&encounter=${encounter}&useCache=${useCache}`;
//   const promise = fetch(url, {
//     cache: "no-store",
//     method: "GET",
//   })
//     .catch((error) => console.error("Error:", error))
//     .then((response) => response.json())
//     .then((data) => {
//       //console.log("Got Procedure data. It be", data);
//       return data;
//     });
//   return promise;
// };

// const getNutritionOrders = (
//   patientId,
//   patientMRN,
//   accessToken,
//   env,
//   encounter,
//   useCache,
//   application,
// ) => {
//   if (application !== CIRRHOSIS_APP) {
//     console.log("In getNutritionOrders, returning empty ones");
//     return Promise.resolve([]);
//   }

//   console.log("In getNutritionOrders");
//   const url = `${LOCAL_NUTRITION_ORDERS}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}&encounter=${encounter}&useCache=${useCache}`;
//   const promise = fetch(url, {
//     cache: "no-store",
//     method: "GET",
//   })
//     .catch((error) => console.error("Error:", error))
//     .then((response) => response.json())
//     .then((data) => {
//       //console.log("Got Nutrition Orders data. It be", data);
//       return data;
//     });
//   return promise;
// };

// const getDiagnosticReports = (
//   patientId,
//   patientMRN,
//   accessToken,
//   env,
//   encounter,
//   useCache,
//   application,
// ) => {
//   if (application !== CIRRHOSIS_APP) {
//     console.log("In getDiagnosticReports, returning empty ones");
//     return Promise.resolve([]);
//   }

//   console.log("In getDiagnosticReports");
//   const url = `${LOCAL_DIAGNOSTIC_REPORTS}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}&encounter=${encounter}&useCache=${useCache}`;
//   const promise = fetch(url, {
//     cache: "no-store",
//     method: "GET",
//   })
//     .catch((error) => console.error("Error:", error))
//     .then((response) => response.json())
//     .then((data) => {
//       //console.log("Got Diagnostic Reports data. It be", data);
//       return data;
//     });
//   return promise;
// };

const getAllergies = (
  patientId,
  patientMRN,
  accessToken,
  env,
  useCache,
  setStatuses
) => {
  const url = `${LOCAL_ALLERGY}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}&useCache=${useCache}`;
  const promise = fetch(url, {
    cache: "no-store",
    method: "GET",
  })
    .catch((error) => console.error("Error:", error))
    .then((response) => response.json())
    .then((data) => {
      //console.log("Got Allergy data. It be", data)
      if (setStatuses) {
        setStatuses(null, null, null, null, "complete");
      }
      return data;
    });
  return promise;
};

const getObservations = (
  patientId,
  patientMRN,
  accessToken,
  env,
  useCache,
  application,
  setStatuses
) => {
  const url = `${LOCAL_OBSERVATION}?access_token=${accessToken}&patient=${patientId}&env=${env}&mrn=${patientMRN}&date=${getEarliestDataDate()}&useCache=${useCache}&app=${application}`;
  const promise = fetch(url, {
    cache: "no-store",
    method: "GET",
  })
    .catch((error) => console.error("Error:", error))
    .then((response) => response.json())
    .then((data) => {
      //console.log("Got Observation data. It be", data);
      if (setStatuses) {
        setStatuses(null, null, null, "complete", null);
      }
      return data;
    });
  return promise;
};

let jsonContext;
if (hardcodedMrn) {
  jsonContext = require.context("./data/hardcoded_fhir", true, /\.json$/);
}

function loadJsonFile(filename) {
  if (hardcodedMrn) {
    return jsonContext("./" + filename);
  } else {
    return {};
  }
}

const getFHIRData = (
  application,
  patientId,
  accessToken,
  env,
  encounter,
  client,
  updatePatientCallback,
  errorHandler,
  mrnList,
  mrnIndex,
  setStatuses,
  useCache = true
) => {
  console.log("Making FHIR calls for ", patientId);

  const start = new Date();
  const q = new URLSearchParams();
  q.set(
    "code",
    [
      "http://loinc.org|55284-4", // BP, systolic & diastolic combined
      "http://loinc.org|33914-3", // Effective glomerular filtration rate
      "http://loinc.org|14959-1", // Microalbumin/Creatinine ratio
      "http://loinc.org|10230-1", // Left ventricular ejection fraction
      "http://loinc.org|2947-0", // Sodium in blood
      "http://loinc.org|2951-2", // Sodium in serum plasma
      "http://loinc.org|6298-4", // Potassium in blood
      "http://loinc.org|2823-3", // Potassium in serum/plasma
      "http://loinc.org|72166-2", // Smoking status
    ].join(",")
  );
  q.set("subject", patientId);

  let dataPromises;

  if (hardcodedMrn) {
    dataPromises = [
      loadJsonFile(`patient_${hardcodedMrn}.json`),
      loadJsonFile(`allergies_${hardcodedMrn}.json`),
      loadJsonFile(`conditions_${hardcodedMrn}.json`),
      //loadJsonFile(`procedures_${hardcodedMrn}.json`),
      //loadJsonFile(`nutrition_orders_${hardcodedMrn}.json`),
      //loadJsonFile(`diagnostic_reports_${hardcodedMrn}.json`),
      loadJsonFile(`medications_${hardcodedMrn}.json`),
      loadJsonFile(`observations_${hardcodedMrn}.json`),
    ];
  } else {
    dataPromises = [
      client
        ? client.request(`/Patient?_id=${patientId}`, {
            pageLimit: 0,
            flat: true,
          })
        : getPatient(
            patientId,
            accessToken,
            env,
            "dummy",
            useCache,
            setStatuses
          ),

      client
        ? client.request(`/AllergyIntolerance?patient=${patientId}`, {
            pageLimit: 0,
            flat: true,
          })
        : getAllergies(
            patientId,
            patientId,
            accessToken,
            env,
            useCache,
            setStatuses
          ),

      client
        ? client.request(`/Condition?patient=${patientId}`, {
            pageLimit: 0,
            flat: true,
          })
        : getConditions(
            patientId,
            patientId,
            accessToken,
            env,
            encounter,
            useCache,
            setStatuses
          ),

      // getProcedures(
      //   patientId,
      //   patientId,
      //   accessToken,
      //   env,
      //   encounter,
      //   useCache,
      //   application,
      // ),

      // getNutritionOrders(
      //   patientId,
      //   patientId,
      //   accessToken,
      //   env,
      //   encounter,
      //   useCache,
      //   application,
      // ),

      // getDiagnosticReports(
      //   patientId,
      //   patientId,
      //   accessToken,
      //   env,
      //   encounter,
      //   useCache,
      //   application,
      // ),

      client
        ? client.request(`/MedicationRequest?patient=${patientId}`, {
            pageLimit: 0,
            //resolveReferences: ["medicationReference", "encounter", "requester", "reasonReference", "dosage"],
            flat: true,
          })
        : getMedications(
            patientId,
            patientId,
            accessToken,
            env,
            useCache,
            setStatuses
          ),

      client
        ? client.request(`Observation?${q}`, {
            pageLimit: 0,
            resolveReferences: ["performer"],
            flat: true,
          })
        : getObservations(
            patientId,
            patientId,
            accessToken,
            env,
            useCache,
            application,
            setStatuses
          ),
    ];
  }
  Promise.all(dataPromises)
    .then(
      ([
        patientObj,
        allergyIntolerances,
        conditions,
        // procedures,
        // nutritionOrders,
        // diagnosticReports,
        medications,
        observations,
      ]) => {
        console.log(`Fetching FHIR Data took: ${new Date() - start}`);

        console.log("Raw observations are:", observations);
        console.log("Raw allergies are:", allergyIntolerances);
        console.log("Raw medications are:", medications);
        console.log("Raw conditions are:", conditions);
        console.log("Raw patient data is", patientObj);
        // console.log("Raw procedures data is", procedures);
        // console.log("Raw nutrition orders data is", nutritionOrders);
        // console.log("Raw diagnostic reports data is", diagnosticReports);

        //const updatedAt = getCacheDate(patientId, accessToken, env);

        let { patient, goal } = patientObj[0];
        // Hack in case we are getting the patient from the public sandbox
        // which uses the "client" interface and does not embed the patient
        // object along with the goal object
        if (!patient) {
          patient = patientObj[0];
          goal = null;
        }

        const measurements = extractMeasurementsFromObservations(
          client,
          observations
        );
        if (samplePatient) {
          measurements.bpMeasurements = sampleBPMeasurements;
        }

        let allergies;

        if (querySbp || queryDbp) {
          addBPsFromQuery(measurements, querySbp || [], queryDbp || []);
        }

        if (queryAllergies) {
          allergies = queryAllergies;
        } else {
          allergies = extractAllergiesFromFHIR(allergyIntolerances);
        }

        if (queryMeds) {
          medications = extractMedicationsFromQuery(queryMeds, queryTabs);
        } else if (samplePatient) {
          console.log("Using sample patient medications");
          medications = extractMedicationsFromFHIR(sampleMedications);
        } else {
          medications = extractMedicationsFromFHIR(medications);
        }

        //procedures = extractProceduresFromFHIR(procedures);

        if (queryConds) {
          conditions = extractConditionsFromQuery(queryConds);
        } else {
          conditions = extractConditionsFromFHIR(conditions);
        }

        if (queryEgfr) {
          measurements.egfrMeasurements = extractEGFRFromQuery(queryEgfr);
        }

        const patientMRN = getPatientMRN(patient);
        console.log(`Fetching & Parsing FHIR Data took: ${new Date() - start}`);
        updatePatientCallback(
          patient,
          patientMRN,
          conditions,
          medications,
          measurements,
          allergies,
          null,
          null,
          null,
          // procedures,
          // nutritionOrders,
          // diagnosticReports,
          goal,
          mrnList,
          mrnIndex
        );
      }
    )
    .catch((error) => {
      console.log("Error handler for in FHIRAPI");
      console.log(error);
      errorHandler([], mrnIndex, error);
    });
};

const getPatientMRN = (patient) => {
  let mrn = null;
  if (patient && patient.identifier) {
    patient.identifier.forEach((identifier) => {
      if (identifier.type && identifier.type.text === "MRN") {
        mrn = identifier.value;
      }
    });
  }
  return mrn;
};

export { getFHIRData, extractMedicationsFromFHIR, getPatient };
