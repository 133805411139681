const sampleBPMeasurements = [
    {
        "date": "2023-10-11T20:08:00Z",
        "systolic": 123,
        "diastolic": 63
    },
    {
        "date": "2023-10-11T16:31:00Z",
        "systolic": 145,
        "diastolic": 86
    },
    {
        "date": "2023-10-10T20:20:00Z",
        "systolic": 114,
        "diastolic": 70
    },
    {
        "date": "2023-10-10T16:28:00Z",
        "systolic": 136,
        "diastolic": 63
    },
    {
        "date": "2023-09-29T16:33:00Z",
        "systolic": 133,
        "diastolic": 67
    },
    {
        "date": "2023-09-13T20:48:00Z",
        "systolic": 125,
        "diastolic": 63
    },
    {
        "date": "2023-09-13T16:34:00Z",
        "systolic": 157,
        "diastolic": 70
    },
    {
        "date": "2023-09-12T20:06:00Z",
        "systolic": 110,
        "diastolic": 54
    },
    {
        "date": "2023-09-12T16:18:00Z",
        "systolic": 144,
        "diastolic": 69
    },
    {
        "date": "2023-08-16T16:19:00Z",
        "systolic": 138,
        "diastolic": 76
    },
    {
        "date": "2023-08-15T21:07:00Z",
        "systolic": 122,
        "diastolic": 67
    },
    {
        "date": "2023-08-15T16:39:00Z",
        "systolic": 147,
        "diastolic": 78
    },
    {
        "date": "2023-07-20T23:12:00Z",
        "systolic": 141,
        "diastolic": 58
    },
    {
        "date": "2023-07-20T19:36:00Z",
        "systolic": 114,
        "diastolic": 54
    },
    {
        "date": "2023-07-20T16:40:00Z",
        "systolic": 136,
        "diastolic": 72
    },
    {
        "date": "2023-07-19T19:39:00Z",
        "systolic": 117,
        "diastolic": 55
    },
    {
        "date": "2023-07-19T16:25:00Z",
        "systolic": 149,
        "diastolic": 64
    },
    {
        "date": "2023-07-18T19:32:00Z",
        "systolic": 138,
        "diastolic": 73
    },
    {
        "date": "2023-07-18T16:20:00Z",
        "systolic": 141,
        "diastolic": 66
    },
    {
        "date": "2023-06-30T16:09:00Z",
        "systolic": 119,
        "diastolic": 67
    },
    {
        "date": "2023-06-22T19:26:00Z",
        "systolic": 128,
        "diastolic": 50
    },
    {
        "date": "2023-06-22T16:22:00Z",
        "systolic": 148,
        "diastolic": 69
    },
    {
        "date": "2023-06-21T19:59:00Z",
        "systolic": 112,
        "diastolic": 57
    },
    {
        "date": "2023-06-21T16:12:00Z",
        "systolic": 154,
        "diastolic": 70
    },
    {
        "date": "2023-06-20T19:10:00Z",
        "systolic": 101,
        "diastolic": 55
    },
    {
        "date": "2023-06-20T16:33:00Z",
        "systolic": 153,
        "diastolic": 77
    },
    {
        "date": "2023-05-25T19:40:00Z",
        "systolic": 106,
        "diastolic": 60
    },
    {
        "date": "2023-05-25T16:55:00Z",
        "systolic": 143,
        "diastolic": 68
    },
    {
        "date": "2023-05-24T19:55:00Z",
        "systolic": 106,
        "diastolic": 50
    },
    {
        "date": "2023-05-24T16:43:00Z",
        "systolic": 149,
        "diastolic": 67
    },
    {
        "date": "2023-05-23T20:20:00Z",
        "systolic": 126,
        "diastolic": 56
    },
    {
        "date": "2023-05-23T17:02:00Z",
        "systolic": 147,
        "diastolic": 71
    },
    {
        "date": "2023-04-27T19:51:00Z",
        "systolic": 130,
        "diastolic": 52
    },
    {
        "date": "2023-04-27T16:49:00Z",
        "systolic": 147,
        "diastolic": 64
    },
    {
        "date": "2023-04-26T16:16:00Z",
        "systolic": 148,
        "diastolic": 69
    },
    {
        "date": "2023-04-25T18:41:00Z",
        "systolic": 109,
        "diastolic": 53
    },
    {
        "date": "2023-04-25T15:58:00Z",
        "systolic": 155,
        "diastolic": 79
    },
    {
        "date": "2023-04-18T17:02:00Z",
        "systolic": 134,
        "diastolic": 57
    },
    {
        "date": "2023-03-30T20:05:00Z",
        "systolic": 128,
        "diastolic": 64
    },
    {
        "date": "2023-03-30T16:32:00Z",
        "systolic": 147,
        "diastolic": 69
    },
    {
        "date": "2023-03-29T19:47:00Z",
        "systolic": 119,
        "diastolic": 55
    },
    {
        "date": "2023-03-29T16:49:00Z",
        "systolic": 156,
        "diastolic": 73
    },
    {
        "date": "2023-03-28T18:53:00Z",
        "systolic": 119,
        "diastolic": 54
    },
    {
        "date": "2023-03-28T15:59:00Z",
        "systolic": 142,
        "diastolic": 67
    },
    {
        "date": "2023-03-17T16:26:00Z",
        "systolic": 110,
        "diastolic": 64
    },
    {
        "date": "2023-03-02T20:37:00Z",
        "systolic": 123,
        "diastolic": 58
    },
    {
        "date": "2023-03-02T17:29:00Z",
        "systolic": 146,
        "diastolic": 75
    },
    {
        "date": "2023-03-01T20:19:00Z",
        "systolic": 110,
        "diastolic": 57
    },
    {
        "date": "2023-03-01T16:56:00Z",
        "systolic": 142,
        "diastolic": 69
    },
    {
        "date": "2023-02-28T20:59:00Z",
        "systolic": 109,
        "diastolic": 54
    },
    {
        "date": "2023-02-28T17:35:00Z",
        "systolic": 139,
        "diastolic": 75
    },
    {
        "date": "2023-02-15T22:25:00Z",
        "systolic": 122,
        "diastolic": 51
    },
    {
        "date": "2023-02-15T17:09:00Z",
        "systolic": 145,
        "diastolic": 75
    },
    {
        "date": "2023-02-02T20:43:00Z",
        "systolic": 118,
        "diastolic": 56
    },
    {
        "date": "2023-02-02T17:30:00Z",
        "systolic": 148,
        "diastolic": 65
    },
    {
        "date": "2023-02-01T20:41:00Z",
        "systolic": 109,
        "diastolic": 53
    },
    {
        "date": "2023-02-01T17:12:00Z",
        "systolic": 133,
        "diastolic": 74
    },
    {
        "date": "2023-01-31T19:44:00Z",
        "systolic": 115,
        "diastolic": 53
    },
    {
        "date": "2023-01-31T16:42:00Z",
        "systolic": 139,
        "diastolic": 72
    },
    {
        "date": "2023-01-05T19:56:00Z",
        "systolic": 132,
        "diastolic": 57
    },
    {
        "date": "2023-01-05T17:19:00Z",
        "systolic": 153,
        "diastolic": 72
    },
    {
        "date": "2023-01-04T20:42:00Z",
        "systolic": 119,
        "diastolic": 56
    },
    {
        "date": "2023-01-04T17:16:00Z",
        "systolic": 135,
        "diastolic": 67
    },
    {
        "date": "2023-01-03T17:02:00Z",
        "systolic": 144,
        "diastolic": 72
    },
    {
        "date": "2022-12-15T20:25:00Z",
        "systolic": 121,
        "diastolic": 64
    },
    {
        "date": "2022-12-15T17:29:00Z",
        "systolic": 141,
        "diastolic": 66
    },
    {
        "date": "2022-12-14T20:30:00Z",
        "systolic": 112,
        "diastolic": 62
    },
    {
        "date": "2022-12-14T17:15:00Z",
        "systolic": 156,
        "diastolic": 74
    },
    {
        "date": "2022-12-13T20:34:00Z",
        "systolic": 119,
        "diastolic": 54
    },
    {
        "date": "2022-12-13T17:45:00Z",
        "systolic": 157,
        "diastolic": 73
    },
    {
        "date": "2022-11-23T20:03:00Z",
        "systolic": 127,
        "diastolic": 71
    },
    {
        "date": "2022-11-23T17:10:00Z",
        "systolic": 149,
        "diastolic": 68
    },
    {
        "date": "2022-11-22T19:48:00Z",
        "systolic": 122,
        "diastolic": 60
    },
    {
        "date": "2022-11-22T16:48:00Z",
        "systolic": 145,
        "diastolic": 63
    },
    {
        "date": "2022-11-21T20:17:00Z",
        "systolic": 127,
        "diastolic": 53
    },
    {
        "date": "2022-11-21T17:02:00Z",
        "systolic": 148,
        "diastolic": 58
    },
    {
        "date": "2022-11-14T21:18:00Z",
        "systolic": 140,
        "diastolic": 68
    },
    {
        "date": "2022-11-03T19:02:00Z",
        "systolic": 113,
        "diastolic": 62
    },
    {
        "date": "2022-11-03T15:51:00Z",
        "systolic": 148,
        "diastolic": 71
    },
    {
        "date": "2022-11-02T18:37:00Z",
        "systolic": 139,
        "diastolic": 69
    },
    {
        "date": "2022-11-02T15:27:00Z",
        "systolic": 146,
        "diastolic": 69
    },
    {
        "date": "2022-11-01T18:56:00Z",
        "systolic": 119,
        "diastolic": 60
    },
    {
        "date": "2022-11-01T15:27:00Z",
        "systolic": 135,
        "diastolic": 72
    }
]

export { sampleBPMeasurements }