import React from "react";
import { Router, Route } from "react-router-dom";
import Display from "./Display";
import Launcher from "./DemoLauncher";
import Status from "./Status";
//import UCSFLauncher from "./UCSFLauncher";
import NYULauncher from "./NYULauncher";
import UCSFBulkReportLauncher from "./UCSFBulkReportLauncher";
import DebugLauncher from "./DebugLauncher";
import Home from "./Home";
import BPActivateReport from "./BPActivateReport";
import history from "./history";
import "./App.scss";
import { searchUrlParams } from "./shared/utils.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientMRN: null,
    };

    this.env = "";
    const env_str = searchUrlParams("env");
    if (env_str) {
      this.env = env_str;
    } else {
      console.log("No env string given");
    }
  }

  render() {
    console.log(`In App.js render, patient id is: ${this.state.patientId}`);
    return (
      <div id="App">
        <Router history={history}>
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/demo" render={() => <Launcher />} />
          <Route exact path="/status/" component={Status} />
          <Route path="/launch/">
            <NYULauncher env={this.env} />
          </Route>
          {/* <Route path="/bpabulk/">
            <UCSFBulkReportLauncher env={this.env} />
          </Route> */}
          <Route path="/debug/">
            <DebugLauncher env={this.env} />
          </Route>
          <Route exact path="/display" render={() => <Display />} />
          {/* <Route exact path="/bpa" render={() => <BPActivateReport />} /> */}
          {/* <Route exact path="/crx-launch" render={() => <CirrhosisRx />} /> */}
        </Router>
      </div>
    );
  }
}

export default App;
