import React from "react";
import './Home.scss';

export default function Home() {

  return (
    <div className="Home">
      <h1>Welcome to EngageRx</h1>
      <p>Launch through Epic to see real data.</p>
      <p>or</p>
      <a href="/demo">See demo version with fake patient data.</a>
    </div>
  );
}