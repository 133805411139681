import React from 'react';
import './BP.scss';
import Expander from "./shared/Expander";
import BPChart from "./BPChart";
import ReactTooltip from 'react-tooltip';

import { clinicBPStats, homeBPStats, sortMeasurements, formatDate, getMeasurementsForNumDays } from './shared/utils.js'
var dayjs = require('dayjs')

const MILLIS_IN_A_DAY = 86400000;
const DAYS_IN_YEAR = 365;
const DAYS_IN_QUARTER = 91;
const DAYS_IN_WEEK = 7;
const DEFAULT_NUM_BP_DATES = 7;
const CLINIC = 'clinic';
const YEAR = 'year';
const QUARTER = 'quarter';
const WEEK = 'week';

class BP extends React.Component {

  constructor(props) {
    super(props);
    const tmpLastHomeBPDate = props.homeMeasurements.length > 0 ?  props.homeMeasurements[0]['date'] : null;

    this.state = {
      sbp: "",
      dbp: "",
      lastHomeBPDate: tmpLastHomeBPDate,
      homeBPDate: null,
      averageSbp: null,
      averageDdp: null,
      chosenChartButton: YEAR,
      showClinicData: true,
      sortedHomeBPData: sortMeasurements(props.homeMeasurements),
      showHomeData: false,
      chosenSourceButton: CLINIC,
      chartDuration: DAYS_IN_YEAR,
      numBPDates: DEFAULT_NUM_BP_DATES,
      allowAverageAdd: true,
      allowPointAdd: true,
      dateFocused: false
    };
  }

  pickTime = (days, button) => {
    this.setState({chartDuration: days,
                   chosenChartButton: button});
  }

  setRange = (days) => {
    this.setState({numBPDates: days});
  }

  render() {
    if (this.props.measurements.length === 0 && this.props.homeMeasurements.length === 0) {
      return (
        <div id="BP">
          <p>No Blood Pressure data available</p>
        </div>
      )
    }
    const { chartDuration, 
            chosenChartButton,
            showClinicData,
            sortedHomeBPData,
            lastHomeBPDate,
            showHomeData,
            numBPDates, 
            } = this.state;

    console.log("Num Dates is:", numBPDates);
    console.log("Got Home data", sortedHomeBPData)
    console.log("Got clinic data", this.props.measurements)
    const homeBPDataInDatesRange = sortedHomeBPData.slice(0, numBPDates)
    const homeStats = homeBPStats(homeBPDataInDatesRange, this.props.averageSbp, this.props.averageDbp);

    //const clinicStats = clinicBPStats(this.props.measurements.slice(0, numBPDates));
    const clinicBPDataInDatesRange = getMeasurementsForNumDays(this.props.measurements, numBPDates);
    const clinicStats = clinicBPStats(clinicBPDataInDatesRange);

    console.log("Got Home Stats!")
    console.log(homeStats);
    console.log("Got clinic Stats!")
    console.log(clinicStats);

    let homeDataWarning = 'No available home BP data';
    if (homeBPDataInDatesRange.length > 0) {
      console.log("Creating warning for home BP data")
      homeDataWarning = `No available home BP data since ${dayjs(lastHomeBPDate).format('MM/DD/YYYY')}`;
      console.log("Warning is", homeDataWarning);
    } 

    let clinicDataIsOld;
    let homeDataIsOld;
    let clinicDataWarning = 'No available clinic BP data';
    if (this.props.measurements.length > 0) {
      clinicDataIsOld = dayjs(this.props.measurements[0].date).valueOf() < dayjs().valueOf() - MILLIS_IN_A_DAY * DAYS_IN_YEAR;
      homeDataIsOld = dayjs(lastHomeBPDate).valueOf() < dayjs().valueOf() - MILLIS_IN_A_DAY * chartDuration;
      clinicDataWarning = `No available clinic BP data since ${new Date(this.props.measurements[0].date).toLocaleDateString()}`;
    }

    return (
      <div id="BP">
        <ReactTooltip 
          clickable={true} 
          place="right" 
          html={true} 
          event="click"
          className="tooltip-extra-class"
        />
        <div className="summary">
          <div className="dropdown">
            <select value={numBPDates} onChange={e => { this.setRange(e.target.value); }} >
              <option key="7" value="7">Last 7 dates</option>
              <option key="30" value="30">Last 30 dates</option>
            </select>
          </div>
          <div className="clinic">
            <table>
              <thead>
                <tr>
                  <th colSpan="3">
                    Clinic BP
                  </th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>{formatDate(clinicStats.firstDate)}</td>
                <td>Current</td>
                <td>{clinicStats.currentSystolic}/{clinicStats.currentDiastolic} mmHg</td>
              </tr>
              <tr>
                <td>{formatDate(clinicStats.prevDate)}</td>
                <td></td>
                <td>{clinicStats.prevSystolic}/{clinicStats.prevDiastolic} mmHg</td>
              </tr>
              <tr>
                <td>{formatDate(clinicStats.prevPrevDate)}</td>
                <td></td>
                <td>{clinicStats.prevPrevSystolic}/{clinicStats.prevPrevDiastolic} mmHg</td>
              </tr>
              <tr>
                <td className="one-row">
                  {formatDate(clinicStats.lastDate)}-{formatDate(clinicStats.firstDate)}
                  <span className="tooltip"  data-tip="Displayed are the last three BP measurements and a range of BP measurements from the last 7 dates with measurements">
                    i
                  </span>

                </td>
                <td>Range</td>
                <td>{clinicStats.lowSystolic}-{clinicStats.highSystolic}/{clinicStats.lowDiastolic}-{clinicStats.highDiastolic} mmHg</td>
              </tr>
              </tbody>
            </table>

          </div>
          <div className="home">
            <table>
              <thead>
                <tr>
                  <th colSpan="2">
                    Home BP
                  </th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>Mean</td>
                { homeStats.systolicMean && homeStats.diastolicMean ?
                  <td>{homeStats.systolicMean}/{homeStats.diastolicMean} mmHg</td>
                  :
                  <td>N/A</td>
                }
              </tr>
              <tr>
                <td>Median</td>
                { homeStats.systolicMedian && homeStats.diastolicMedian ?
                  <td>{homeStats.systolicMedian}/{homeStats.diastolicMedian} mmHg</td>
                  :
                  <td>N/A</td>
                }
              </tr>
              <tr>
                <td>Range</td>
                { homeStats.lowSystolic && homeStats.highSystolic && homeStats.lowDiastolic && homeStats.highDiastolic ?
                  <td>{homeStats.lowSystolic}-{homeStats.highSystolic}/{homeStats.lowDiastolic}-{homeStats.highDiastolic} mmHg</td>
                  :
                  <td>N/A</td>
                }
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <Expander title="Graph view" subcontent={true} >
          <div className="chart">
            <div className="source buttons">
              <button className={`clinic ${showClinicData ? 'selected' : ''}`} onClick={() => this.setState({showClinicData: !showClinicData})}>
                Clinic
              </button>
              <button className={`home ${showHomeData ? 'selected' : ''}`} onClick={() => this.setState({showHomeData: !showHomeData})}>
                Home
              </button>
            </div>
            <div className="time buttons">
              <button className={`year ${chosenChartButton === YEAR ? 'selected' : ''}`} onClick={() => this.pickTime(DAYS_IN_YEAR, YEAR)}>
                Last 12 Months
              </button>
              <button className={`quarter ${chosenChartButton === QUARTER ? 'selected' : ''}`} onClick={() => this.pickTime(DAYS_IN_QUARTER, QUARTER)}>
                Last 3 Months
              </button>
              <button className={`week ${chosenChartButton === WEEK ? 'selected' : ''}`} onClick={() => this.pickTime(DAYS_IN_WEEK, WEEK)}>
                Last Week
              </button>
            </div>
            <BPChart 
              measurements={clinicBPDataInDatesRange}
              homeMeasurements={homeBPDataInDatesRange}
              numBPDates={numBPDates}
              chartDuration={chartDuration}
              clinicGoalSBP={this.props.clinicGoalSBP}
              clinicGoalDBP={this.props.clinicGoalDBP}
              showClinicData={showClinicData}
              showHomeData={showHomeData}
              forBPA={false}
            />
            { clinicDataIsOld && showClinicData &&
              <p className="warning">{clinicDataWarning}</p>
            }
            { homeDataIsOld && showHomeData &&
              <p className="warning">{homeDataWarning}</p>
            }
          </div>
        </Expander>
        {/* 
        <Expander className="form" title="Manually enter Home BP measurements" subcontent="true" >
          <div className="summary form">
            <div className="forms data-entry">
              <form className="individual_homeBp" onSubmit={this.handleHomeBPSubmit}>
                <p className="subheader">Single BP</p>
                <div className="form-row">
                  <label>SBP:</label>
                    <input className="bp-input sbp" type="text" name="sbp" value={sbp} onChange={this.handleHomeSBPChange} />
                </div>
                <div className="form-row">
                  <label className="dbp">DBP:
                  </label>
                    <input className="bp-input dbp" type="text" name="dbp" value={dbp} onChange={this.handleHomeDBPChange} />
                </div>
                <div className="form-row date">
                  <label className="date-input">Date:</label>

                  <DatePicker 
                    onChange={this.handleDateChange}
                    yearPlaceholder={""}
                    monthPlaceholder={""}
                    dayPlaceholder={""}
                    calendarIcon={null}
                    clearIcon={null}
                    value={this.state.homeBPDate}
                  />
                </div>
                <input className={`submit ${allowPointAdd === false ? "disabled" : ""}`} type="submit" value="Add" />
              </form>

              <form className="average_homeBp dbp"  onSubmit={this.handleAverageHomeBPSubmit}>
                <p className="subheader">Average BP</p>
                <div className="form-row">
                  <label>Average SBP:</label>
                  <input className="bp-input sbp" type="text" name="sbp" value={averageSbp || ""} onChange={this.handleAverageHomeSBPChange} />
                </div>
                <div className="form-row">
                  <label>Average DBP:</label>
                  <input className="bp-input dbp" type="text" name="dbp" value={averageDbp || ""} onChange={this.handleAverageHomeDBPChange} />
                </div>
                <input className={`submit ${allowAverageAdd === false ? "disabled" : ""}`} type="submit" value="Add" />
              </form>
            </div>
          </div>
        </Expander>
        */}
      </div>
    );
  }
}

export default BP;