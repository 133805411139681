import React from "react";
import './Labs.scss';
import { formatDate } from './shared/utils.js'
var dayjs = require('dayjs')

function uniquifyLabs (measurements)  {
  const uniqueMeasurements = []
  const uniques = new Set();
  measurements.forEach((measurement) => {
    if (!uniques.has(measurement.type)) {
      uniqueMeasurements.push(measurement)
      uniques.add(measurement.type)
    }
  })
  return uniqueMeasurements.sort((a,b) => a.order - b.order);
}

function normalRange(measurement) {
  var normal = {inRange: true};
  if (measurement.referenceRange) {
    if (measurement.referenceRange[0].low)
      normal.low = measurement.referenceRange[0].low.value;
    if (measurement.referenceRange[0].high)
      normal.high = measurement.referenceRange[0].high.value;
    normal.display = measurement.referenceRange[0].text;

  } else {
    switch (measurement.type) {
      case "Potassium":
        normal.display = "3.2-5.2 mmol/L"
        normal.low = 3.2
        normal.high = 5.2
        break;
      case "Sodium":
        normal.display = "136-145 mmol/L"
        normal.low = 136
        normal.high = 145
        break;
      case "Creatinine":
        normal.display = "0.6-1.2 mg/dL"
        normal.low = 0.6
        normal.high = 0.2
        break;
      case "eGFR":
        normal.display = "≥ 60";
        normal.low = 60;
        normal.high = 999999999;
        break;
      default:
        normal.display = "";
    }
  }
  if (normal.low && measurement.value < normal.low) {
    normal.inRange = false;
  }
  if (normal.high && measurement.value > normal.high) {
    normal.inRange = false;
  }
  return normal;
}

function formattedValue(measurement) {
  var value = "";
  switch (measurement.type.toLowerCase()) {
    case "cholesterol":
    case "ldl cholesterol":
    case "sodium":
    case "egfr":
      value = measurement.value.toFixed(0)
      break;
    case "hemoglobin":
    case "hemoglobin a1c":
    case "potassium":
    case "creatinine":
      value = measurement.value.toFixed(1)
      break;
    default:
      value = measurement.value.toFixed(2)
  }
  return value;
}

function labRows(measurements) {
  return measurements.map((lab, i) => ( 
    <tr key={i+1}>
      <td>{formatDate(dayjs(lab.date))}</td>
      <td>{lab.type}</td>
      {lab.displayValue ?
      <td className={normalRange(lab).inRange === false ? 'out-of-range' : ''}>{lab.displayValue} {lab.unit}</td>
      :
      <td className={normalRange(lab).inRange === false ? 'out-of-range' : ''}>{formattedValue(lab)} {lab.unit}</td>
      }
      <td>{normalRange(lab).display}</td>
    </tr>
  ));
}


export function Labs(props) {
  console.log("*** LABS ARE ***", props.measurements)
  const uniqueLabs = uniquifyLabs(props.measurements)
  return (
    <div id="Labs">
      <div className="expand">
        <div className="table">
          <table>
            <thead>
              <tr key={0}>
                <th>
                  DATE
                </th>
                <th>
                  TYPE
                </th>
                <th>
                  VALUE
                </th>
                <th>
                  NORMAL RANGE 
                </th>
              </tr>
            </thead>
            <tbody>
              {labRows(uniqueLabs)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}