import React from "react";
import Patient from './Patient.jsx';
import EngageRxLoading from "./EngageRxLoading.jsx";
import { searchUrlParams, authenticateWithCallback } from './shared/utils.js'


export default class UCSFLauncher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      accessToken: null,
      encounter: null,
      patientId: null,
      error: null,
      data: null,
      authStatus: "loading",
    };

    // Passed to EngageRx by the SmartButton launcher
    this.token = null;
    const token_str = searchUrlParams('launch')
    if (token_str) {
      this.token = token_str
    } else {
      console.log("No token string given");
    }
    const user_str = searchUrlParams('user')
    if (user_str) {
      this.userId = user_str;
    }
  }

  componentDidMount() {
    console.log("In UCSFLauncher.componentDidMount")
    authenticateWithCallback(this.token, this.props.env, this.userId).then(authData => {
      console.log("Got this authData in componentDidMount", authData)
      if (authData) {
        this.setState({
          accessToken: authData.accessToken, 
          encounter: authData.encounter,
          patientId: authData.patientId,
          authStatus: "complete"
        })
      } else {
        this.setState({ authStatus: null })
        console.log("Caught an error during authentication");
      }
    }).catch(authError => {
      this.setState({ authStatus: null, error: "Error while authenticating" })
      console.log("Caught an error during componentDidMount", authError)
    })
  }

  render() {
    console.log("In UCSFLauncher.render with this state", this.state)
    const { accessToken, patientId, encounter, error } = this.state;
    console.log("In render have this accessToken", accessToken)

    return (
      <div id="UCSFLauncher">
        {error ?
             <p>Error: {error}</p> 
           :
           <div>
            { (accessToken) ? 
                <Patient patientId={patientId} accessToken={accessToken} env={this.props.env} encounter={encounter} />
              :
                <EngageRxLoading authStatus={this.state.authStatus} />
            }
          </div>
        }
      </div>
    )
  }
}
