import React, { useState } from "react";
import "./BPGoal.scss";

export function BPGoal({ handleBPGoal, currentSbp, currentDbp }) {
  const [sbp, setSbp] = useState(currentSbp);
  const [dbp, setDbp] = useState(currentDbp);

  const is14090 = currentSbp === 140 && currentDbp === 90;
  const is13080 = currentSbp === 130 && currentDbp === 80;

  console.log(
    ", Goals are: ",
    typeof currentSbp,
    currentSbp,
    typeof currentDbp,
    currentDbp
  );

  return (
    <div id="BPGoal" onClick={(event) => event.stopPropagation()}>
      <div className="radio-button-list section">
        <p>Select one</p>
        <div className="radio-button" onClick={() => handleBPGoal(140, 90)}>
          {is14090 ? (
            <input type="radio" name="option" id="14090" defaultChecked />
          ) : (
            <input type="radio" name="option" id="14090" />
          )}
          <label htmlFor="14090" className="radio-label">
            <span className="radio">140/90 mmHg</span>
          </label>
        </div>
        <div className="radio-button" onClick={() => handleBPGoal(130, 80)}>
          {is13080 ? (
            <input type="radio" name="option" id="13080" defaultChecked />
          ) : (
            <input type="radio" name="option" id="13080" />
          )}
          <label htmlFor="13080" className="radio-label">
            <span className="radio">130/80 mmHg</span>
          </label>
        </div>
      </div>
      <div className="add-new section">
        <p>Or add new</p>
        <div className="manual">
          <span>BP goal</span>
          <input
            type="number"
            value={sbp || ""}
            onChange={(event) => setSbp(event.target.value)}
          />
          <span>/</span>
          <input
            type="number"
            value={dbp || ""}
            onChange={(event) => setDbp(event.target.value)}
          />
        </div>
        <button onClick={() => handleBPGoal(sbp, dbp)}>Add</button>
      </div>
    </div>
  );
}
