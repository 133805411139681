import React from "react";
import Modal from "react-modal";
import "./Patient.scss";
import { FhirClientContext } from "./FhirClientContext";
import { Header } from "./Header";
import Recommendations from "./Recommendations";
import { Rationale } from "./Rationale";
import OtherSuggestions from "./OtherSuggestions";
import Expander from "./shared/Expander";
import { Medications } from "./Medications";
import { Labs } from "./Labs";
import BP from "./BP";
import EngageRxLoading from "./EngageRxLoading.jsx";
import { analyzePatient } from "./algorithm.js";
import { getFHIRData } from "./FHIRApi.js";
import { SubmitFeedback } from "./SubmitFeedback";
import { HYPERTENSION_APP } from "./shared/constants";
import {
  searchUrlParams,
  patientFirstName,
  patientLastName,
  age,
  phoneNumber,
  sortMeasurements,
  logUsage,
  submitFeedback,
  logError,
  setBPGoals,
  getClientName,
  hasTooHighACR,
  isLocalhost,
} from "./shared/utils.js";
import {
  DEFAULT_DIASTOLIC_GOAL,
  DEFAULT_SYSTOLIC_GOAL,
} from "./shared/constants";

let DATA_DATE_API = "/api/earliest-data-date";
if (isLocalhost) {
  DATA_DATE_API = "http://localhost:8081/api/earliest-data-date";
}

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

export function getIsDocumentVisible() {
  return !document[getBrowserDocumentHiddenProp()];
}

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    paddingTop: "20px",
    paddingBottom: "20px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default class Patient extends React.Component {
  static contextType = FhirClientContext;

  constructor(props) {
    super(props);
    this.patientId = null;
    this.userId = null;
    const user_str = searchUrlParams("user");
    if (user_str) {
      this.userId = user_str;
    }

    const id_str = searchUrlParams("id");
    if (id_str) {
      this.patientId = id_str;
    }

    this.state = {
      visible: true,
      loading: true,
      patient: null,
      patientMRN: null,
      showMoreLabs: false,
      showMoreBps: false,
      medications: null,
      allergies: null,
      conditions: null,
      observations: null,
      bpMeasurements: null,
      homeBpMeasurements: null,
      homeBp_average: null,
      acrMeasurements: null,
      egfrMeasurements: null,
      lvefMeasurements: null,
      saltMeasurements: null,
      labMeasurements: null,
      smokingStatus: null,
      recommendations: null,
      homeBpError: null,
      feedbackText: "",
      clinicGoalSBP: DEFAULT_SYSTOLIC_GOAL,
      clinicGoalDBP: DEFAULT_DIASTOLIC_GOAL,
      homeGoalSBP: null,
      homeGoalDBP: null,
      showMoreOptions: {},
      error: null,
      dataDate: null,
      patientStatus: "loading",
      medicationsStatus: "loading",
      conditionsStatus: "loading",
      observationsStatus: "loading",
      allergiesStatus: "loading",
      recommendationsStatus: null,
    };
  }

  onVisibilityChange = () => {
    const currentlyVisible = getIsDocumentVisible();
    if (currentlyVisible && this.state.visible === false) {
      this.handleRefresh();
    }
    this.setState({ visible: currentlyVisible });
  };

  updateBP = (
    homeBpMeasurements,
    averageSbp,
    averageDbp,
    newGoalSbp = null,
    newGoalDbp = null
  ) => {
    console.log("In updateBP, homeBPMeasurements are: ", homeBpMeasurements);
    const recommendations = analyzePatient(
      newGoalSbp,
      newGoalDbp,
      this.state.patient,
      this.state.conditions,
      this.state.medications,
      homeBpMeasurements,
      averageSbp,
      averageDbp,
      this.state.bpMeasurements,
      this.state.egfrMeasurements,
      this.state.lvefMeasurements,
      this.state.acrMeasurements,
      this.state.saltMeasurements,
      this.state.smokingStatus,
      this.state.allergies
    );
    console.log("Re-analyzed patient. Recos now are:");
    console.log(recommendations);
    this.setState({
      recommendations,
      homeBpMeasurements,
      averageSbp,
      averageDbp,
      recommendationsStatus: "complete",
    });
  };

  updatePatient = async (
    patient,
    patientMRN,
    conditions,
    medications,
    measurements,
    allergies,
    procedures,
    nutritionOrders,
    diagnosticReports,
    goal
  ) => {
    logUsage(this.props.env, this.userId, patientMRN, this.props.encounter);
    console.log("In updatePatient");
    this.setState({ recommendationsStatus: "loading" });

    if (hasTooHighACR(measurements.acrMeasurements)) {
      const condObj = {};
      condObj["recordedDate"] = "2019-08-02T12:12:12-04:00";
      condObj["name"] = "Albuminuria";
      conditions.push(condObj);
    }

    const sbp_goal = goal?.sbp_goal || this.state.clinicGoalSBP;
    const dbp_goal = goal?.dbp_goal || this.state.clinicGoalDBP;

    const homeBpMeasurements = sortMeasurements(
      measurements.homeBpMeasurements
    );
    const start = new Date();
    const recommendations = analyzePatient(
      sbp_goal,
      dbp_goal,
      patient,
      conditions,
      medications,
      homeBpMeasurements,
      null,
      null,
      measurements.bpMeasurements,
      measurements.egfrMeasurements,
      measurements.lvefMeasurements,
      measurements.acrMeasurements,
      measurements.saltMeasurements,
      measurements.smokingStatus,
      allergies
    );

    const dataDate = this.getDataDate(
      this.props.env,
      this.patientId,
      this.props.accessToken,
      this.userId
    );

    console.log(`Analyzing Patient took: ${new Date() - start}`);
    this.setState({
      patient,
      patientMRN,
      conditions,
      medications,
      homeBpMeasurements: homeBpMeasurements,
      bpMeasurements: measurements.bpMeasurements,
      egfrMeasurements: measurements.egfrMeasurements,
      acrMeasurements: measurements.acrMeasurements,
      lvefMeasurements: measurements.lvefMeasurements,
      saltMeasurements: measurements.saltMeasurements,
      labMeasurements: measurements.labMeasurements,
      smokingStatus: measurements.smokingStatus,
      showMoreOptions: {},
      clinicGoalSBP: sbp_goal,
      clinicGoalDBP: dbp_goal,
      recommendations,
      allergies,
      loading: false,
      dataDate: dataDate,
      recommendationsStatus: "complete",
    });
  };

  getDataDate = (env, patientId, accessToken, userId) => {
    console.log("data date user id is: ", userId);
    const url = `${DATA_DATE_API}?access_token=${accessToken}&patient=${patientId}&env=${env}&user=${userId}`;
    console.log(`Sending to this url for data date ${url}`);
    fetch(url, { method: "GET" })
      .catch((error) => console.log("Error:", error))
      .then((response) => response.json())
      .then((data) => {
        console.log("Got this data returned for data date", data);
        this.setState({ dataDate: data.date });
      });
  };

  componentDidMount() {
    const client = this.context.client;
    if (!this.patientId) {
      this.patientId = this.props.patientId || client.patient.id;
      // const sbp = parseInt(sessionStorage.getItem(getStorageKey(this.patientId, this.userId, "sbp"))) || this.state.clinicGoalSBP;
      // const dbp = parseInt(sessionStorage.getItem(getStorageKey(this.patientId, this.userId, "dbp"))) || this.state.clinicGoalDBP;
      // this.setState({clinicGoalSBP: sbp, clinicGoalDBP: dbp})
    }
    console.log(`Now this.patient id is: ${this.patientId}`);

    //const visibilityChange = getBrowserVisibilityProp()
    //document.addEventListener(visibilityChange, this.onVisibilityChange, false)

    this.handleRefresh();
  }

  componentWillUnmount() {
    const visibilityChange = getBrowserVisibilityProp();
    document.removeEventListener(visibilityChange, this.onVisibilityChange);
  }

  handleBPGoal = (sbp, dbp) => {
    this.setState({
      clinicGoalSBP: parseInt(sbp),
      clinicGoalDBP: parseInt(dbp),
    });
    console.log(
      "Gonna setBP Goals with:",
      this.state.patientMRN,
      sbp,
      dbp,
      this.userId,
      this.props.env,
      getClientName()
    );
    setBPGoals(
      this.state.patientMRN,
      sbp,
      dbp,
      this.userId,
      this.props.env,
      getClientName(),
      this.props.accessToken,
      this.patientId
    );
    //sessionStorage.setItem(getStorageKey(this.patientId, this.userId, "sbp"), sbp)
    //sessionStorage.setItem(getStorageKey(this.patientId, this.userId, "dbp"), dbp)
    this.updateBP(this.state.homeBpMeasurements, null, null, sbp, dbp);
  };

  handleError = (mrnList, mrnHandler, error) => {
    logError(
      this.props.env,
      this.userId,
      mrnList[0],
      this.props.encounter,
      error
    );
    this.setState({ error });
  };

  handleRefresh = (useCache = true) => {
    this.setState({ loading: true });
    this.setState({
      patientStatus: "loading",
      medicationsStatus: "loading",
      conditionsStatus: "loading",
      observationsStatus: "loading",
      allergiesStatus: "loading",
      recommendationsStatus: false,
    });
    getFHIRData(
      HYPERTENSION_APP,
      this.patientId,
      this.props.accessToken,
      this.props.env,
      this.props.encounter,
      this.context.client,
      this.updatePatient,
      this.handleError,
      null,
      null,
      this.setStatuses,
      useCache
    );
  };

  handleShowMoreLabsClick = () => {
    const currValue = this.state.showMoreLabs;
    this.setState({ showMoreLabs: !currValue });
  };

  handleShowMoreBpsClick = () => {
    const currValue = this.state.showMoreBps;
    this.setState({ showMoreBps: !currValue });
  };

  handleOptionClick = (recoId) => {
    const { showMoreOptions } = this.state;
    if (showMoreOptions.hasOwnProperty(recoId)) {
      showMoreOptions[recoId] = !showMoreOptions[recoId];
    } else {
      showMoreOptions[recoId] = true;
    }
    this.setState({ showMoreOptions });
  };

  handleCloseSubmitFeedback = () => {
    submitFeedback(
      this.props.env,
      this.userId,
      this.state.patientMRN,
      this.props.encounter,
      this.state.feedbackText
    );
    this.setState({ feedbackModalIsOpen: false, feedbackText: "" });
  };

  setFeedbackText = (event) => {
    console.log("Gonna set feedback text to", event.target.value);
    this.setState({ feedbackText: event.target.value });
  };

  setStatuses = (
    patientStatus,
    medicationsStatus,
    conditionsStatus,
    observationsStatus,
    allergiesStatus
  ) => {
    this.setState({
      patientStatus: patientStatus || this.state.patientStatus,
      medicationsStatus: medicationsStatus || this.state.medicationsStatus,
      conditionsStatus: conditionsStatus || this.state.conditionsStatus,
      observationsStatus: observationsStatus || this.state.observationsStatus,
      allergiesStatus: allergiesStatus || this.state.allergiesStatus,
    });
  };

  render() {
    const {
      loading,
      error,
      patient,
      labMeasurements,
      bpMeasurements,
      averageSbp,
      averageDbp,
      homeBpMeasurements,
      recommendations,
      conditions,
      clinicGoalSBP,
      clinicGoalDBP,
    } = this.state;

    if (error) {
      console.error("CAUGHT ERROR: Could not process patient", error);
      return (
        <div id="Patient">
          <h1 className="error">Error: Could not load patient</h1>
          <h2 className="error">{error.message}</h2>
        </div>
      );
    }

    if (loading) {
      return (
        <EngageRxLoading
          authStatus="complete"
          patientStatus={this.state.patientStatus}
          medicationsStatus={this.state.medicationsStatus}
          conditionsStatus={this.state.conditionsStatus}
          observationsStatus={this.state.observationsStatus}
          allergiesStatus={this.state.allergiesStatus}
          recommendationsStatus={this.state.recommendationsStatus}
        />
      );
    }

    //console.log(", IN RENDER, homeBPMeasurments are", homeBpMeasurements);
    console.log(", IN RENDER, goals are", clinicGoalSBP, clinicGoalDBP);

    return (
      <div id="Patient">
        <Header
          userName="Dr. Valy Fontil"
          patientFirstName={patientFirstName(patient.name)}
          patientLastName={patientLastName(patient.name)}
          dob={patient.birthDate}
          handleRefresh={this.handleRefresh}
          handleSubmitFeedback={() =>
            this.setState({ feedbackModalIsOpen: true })
          }
          age={age(patient)}
          mrn={this.state.patientMRN}
          updateDate={this.state.dataDate}
        />

        <div className="columns">
          <div className="column left">
            <div className="recommendations">
              <Recommendations
                conditions={conditions}
                age={age(patient)}
                gender={patient.gender}
                recommendations={recommendations}
                userName="Dr. Valy Fontil"
                phone={phoneNumber(patient)}
                userId={this.userId}
                patientMRN={this.state.patientMRN}
                encounter={this.props.encounter}
                env={this.props.env}
                clinicGoalSBP={clinicGoalSBP}
                clinicGoalDBP={clinicGoalDBP}
                handleBPGoal={this.handleBPGoal}
              />
            </div>
            <div className="rationale">
              <Expander title="Rationale for Recommendations" isClosed={true}>
                <Rationale reasons={recommendations.reasons} />
              </Expander>
            </div>
            <div className="other-suggestions">
              <Expander title="Other Suggestions">
                <OtherSuggestions
                  recommendations={recommendations}
                  age={age(patient)}
                />
              </Expander>
            </div>
          </div>
          <div className="column">
            <div className="medications">
              <Expander title="Medications">
                <Medications
                  medications={recommendations.medications}
                  otherMedications={recommendations.otherMedications}
                />
              </Expander>
            </div>
            <div className="labs">
              <Expander title="Lab Results">
                <Labs measurements={labMeasurements} />
              </Expander>
              <div className="bp">
                <Expander title="BP Measurements">
                  <BP
                    measurements={bpMeasurements}
                    homeMeasurements={homeBpMeasurements}
                    averageSbp={averageSbp}
                    averageDbp={averageDbp}
                    updateBP={this.updateBP}
                    clinicGoalSBP={clinicGoalSBP}
                    clinicGoalDBP={clinicGoalDBP}
                  />
                </Expander>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.feedbackModalIsOpen} style={ModalStyle}>
          <SubmitFeedback
            feedbackText={this.state.feedbackText}
            setFeedbackText={this.setFeedbackText}
            handleSubmitFeedback={this.handleCloseSubmitFeedback}
            handleCancelFeedback={() =>
              this.setState({ feedbackModalIsOpen: false })
            }
          ></SubmitFeedback>
        </Modal>
      </div>
    );
  }
}
