const ACR_RATIO_GOAL_GRAMS = 30;
const ACR_RATIO_GOAL_MILLIMOLES = 3.4;

const DEFAULT_SYSTOLIC_GOAL = 140;
const DEFAULT_DIASTOLIC_GOAL = 90;

const DEFAULT_HOME_SYSTOLIC_GOAL = 135;
const DEFAULT_HOME_DIASTOLIC_GOAL = 85;

const UNCONTROLLED_HYPERTENSION = "Uncontrolled hypertension";
const ELEVATED_BP = "Elevated blood pressure";
const MISSING_DATA = "Missing data";
const OK = "Ok";

const WEEK = "week";
const MONTH = "month";
const QUARTER = "quarter";

const BILIRUBIN_LOINC = "1975-2";
const INR_LOINC = "6301-6";
const INR_LOINC2 = "34714-6";
const CREATININE_LOINC = "2160-0";
const ALBUMIN_LOINC = "1751-7";
const SODIUM_LOINC = "2951-2";
const PROTHROMBIN_TIME_LOINC = "5902-2";
const WBC_LOINC = "26464-8"

const INFECTION_LAB_TYPE = "Infection";
const ASCITES_LAB_TYPE = "Ascites";
const ESOPHAGEAL_VARICES_LAB_TYPE = "Esophageal Varices";
const RENAL_LAB_TYPE = "Renal";

const HYPERTENSION_APP = "hypertension";
const CIRRHOSIS_APP = "cirrhosis";

const CRX_SECTION_NAMES = [
  INFECTION_LAB_TYPE,
  ASCITES_LAB_TYPE,
  ESOPHAGEAL_VARICES_LAB_TYPE,
  RENAL_LAB_TYPE,
];

const CRX_LAB_LOINCS = {
  "8478-0": "MAP",
  "8867-4": "Heart rate",
  "26464-8": "White Blood Cell Count (WBC)",
  "59576-9": "BMI",
  "8302-2": "Height",
  "55284-4": "Blood Pressure",
  "29463-7": "Body weight",
  "600-1": "Blood Culture",
  "13968-1": "Aerobic Bacteria Culture",
  "13967-3": "Anaerobic Bacteria Culture",
  "630-4": "Urine Culture",
  "53758-9": "Urine Culture, Quantitative",
  "68944-2": "Sputum Culture",
  "66594-7": "Wound Culture",
  "18481-6": "Throat Culture",
  "68332-6": "Stool Culture",
  "2515-5": "Fungal Culture",
  "21463-8": "Mycobacteria Culture",
  "56831-2": "Complete Blood Count (CBC)",
  "71875-9": "Hemoglobin A1c",
  "24326-6": "Basic Metabolic Panel (BMP)",
  "24331-6": "Comprehensive Metabolic Panel (CMP)",
  "24324-3": "Lipid Panel",
  "5902-2": "Prothrombin Time (PT)",
  "3173-2": "Partial Thromboplastin Time (PTT)",
  "34714-6": "International Normalized Ratio (INR)",
  "6301-6": "International Normalized Ratio (INR)",
  "3016-3": "Thyroid-Stimulating Hormone (TSH)",
  "8665-2": "Free Thyroxine (T4)",
  "3051-2": "Free Triiodothyronine (T3)",
  "38265-0": "Vitamin D, 25-Hydroxy",
  "71429-8": "C-Reactive Protein (CRP)",
  "30364-4": "Erythrocyte Sedimentation Rate (ESR)",
  "2276-4": "Ferritin",
  "2593-0": "Iron",
  "2599-7": "Total Iron Binding Capacity (TIBC)",
  "2346-7": "Folate",
  "2205-6": "Vitamin B12",
  "49563-0": "Troponin",
  "30934-4": "BNP",
  "48065-7": "D-Dimer",
  "718-7": "Hemoglobin (Hgb)",
  "4544-3": "Hematocrit (Hct)",
  "787-2": "Mean Corpuscular Volume (MCV)",
  "785-6": "Mean Corpuscular Hemoglobin (MCH)",
  "786-4": "Mean Corpuscular Hemoglobin Concentration (MCHC)",
  "788-0": "Red Cell Distribution Width (RDW)",
  "777-3": "Platelet count (PLT)",
  "32623-1": "Mean Platelet Volume (MPV)",
  "751-8": "Neutrophils",
  "731-0": "Lymphocytes",
  "742-7": "Monocytes",
  "711-2": "Eosinophils",
  "704-7": "Basophils",
  "2951-2": "Sodium (Na)",
  "2823-3": "Potassium (K)",
  "2075-0": "Chloride (Cl)",
  "2028-9": "Carbon Dioxide (CO2, bicarconate)",
  "6299-2": "Blood Urea Nitrogen (BUN)",
  "2160-0": "Creatinine",
  "2345-7": "Glucose",
  "17861-6": "Calcium (Ca)",
  "1920-8": "Aspartate Aminotransferase (AST, SGOT)",
  "1742-6": "Alanine Aminotransferase (ALT, SGPT)",
  "6768-6": "Alkaline Phosphatase (ALP)",
  "1975-2": "Total Bilirubin",
  "1960-4": "Direct Bilirubin",
  "1963-8": "Indirect Bilirubin",
  "1751-7": "Albumin",
  "2885-2": "Total Protein",
  "30239-8": "Gamma-Glutamyl Transferase (GGT)",
  "57798-5": "Cell Count",
  "57770-3": "Neutrophil Percentage",
  "599-0": "Fluid Culture",
  "5546-0": "Fluid Gram Stain",
  "29277-3": "Fluid LDH (Lactate Dehydrogenase)",
  "2348-8": "Fluid Glucose",
  "2496-5": "Fluid Amylase",
  "2747-2": "Fluid pH",
  "1747-5": "Fluid Albumin",
  "2881-1": "Fluid Total Protein",
};

const CRX_LAB_LOINCS_SET = new Set(Object.keys(CRX_LAB_LOINCS));

export {
  ACR_RATIO_GOAL_GRAMS,
  ACR_RATIO_GOAL_MILLIMOLES,
  DEFAULT_DIASTOLIC_GOAL,
  DEFAULT_SYSTOLIC_GOAL,
  DEFAULT_HOME_DIASTOLIC_GOAL,
  DEFAULT_HOME_SYSTOLIC_GOAL,
  UNCONTROLLED_HYPERTENSION,
  ELEVATED_BP,
  MISSING_DATA,
  OK,
  WBC_LOINC,
  BILIRUBIN_LOINC,
  INR_LOINC,
  INR_LOINC2,
  CREATININE_LOINC,
  ALBUMIN_LOINC,
  SODIUM_LOINC,
  WEEK,
  MONTH,
  QUARTER,
  HYPERTENSION_APP,
  CIRRHOSIS_APP,
  CRX_LAB_LOINCS,
  CRX_LAB_LOINCS_SET,
  PROTHROMBIN_TIME_LOINC,
  CRX_SECTION_NAMES,
  INFECTION_LAB_TYPE,
  ASCITES_LAB_TYPE,
  ESOPHAGEAL_VARICES_LAB_TYPE,
  RENAL_LAB_TYPE,
};
