import React from 'react';
import checkmark from './shared/checkmark.png'
import spinner from './shared/spinner.gif'
import "./EngageRxLoading.scss";


function EngageRxLoading({ authStatus, patientStatus, medicationsStatus, observationsStatus, conditionsStatus, allergiesStatus, recommendationsStatus}) {
  const iconImage = (status) => {
    let icon;
    switch (status) {
      case "complete":
        icon = <img src={checkmark} alt="loading..." />
        break;
      case "loading":
        icon = <img src={spinner} alt="loading..." />
        break;
      default:
        icon = null;
    }
    return icon
  }


  return (
    <div className="EngageRxLoading">
      <p>EngageRx is loading...</p>
      <ul>
        <li>Auth Status: {iconImage(authStatus)}</li>
        <li>Patient Status: {iconImage(patientStatus)}</li>
        <li>Medications Status: {iconImage(medicationsStatus)}</li>
        <li>Observations Status: {iconImage(observationsStatus)}</li>
        <li>Conditions Status: {iconImage(conditionsStatus)}</li>
        <li>Allergies Status: {iconImage(allergiesStatus)}</li>
        <li>Recommendations Status: {iconImage(recommendationsStatus)}</li>
      </ul>
    </div>
  );
}

export default EngageRxLoading;
